import React, { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';

function ForgotPasswordModal({ forgotOpen, setForgotOpen }) {
  const [email, setEmail] = useState('');

  const submitForgotPassword = e => {
    e.preventDefault();
    axios('/forgot_password', {
      method: 'POST',
      data: { username: email }
    })
      .then(() => {
        toast.success('Email sent');
      })
      .catch(e2 => {
        const error = e2?.response?.data?.error;
        toast.error(error ?? 'Something went wrong!');
      })
      .finally(() => {
        setEmail('');
        setForgotOpen(false);
      });
  };

  return (
    <Transition.Root show={Boolean(forgotOpen)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={() => setForgotOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
        </Transition.Child>
        <form
          onSubmit={submitForgotPassword}
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <button
                  onClick={() => setForgotOpen(false)}
                  className="absolute top-[23px] right-5 opacity-25"
                  type="button"
                  aria-label="Close"
                >
                  <FaTimes />
                </button>
                <div className="mb-5 w-full">
                  <h3 className="leading-none">Forgot password</h3>
                  <p className="mt-1.5 text-xs">
                    Type your email to send a password reset
                  </p>
                </div>
                <div className="grid gap-2.5">
                  <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
                    <div className="w-full">
                      <div className="mb-2.5 flex w-full items-center justify-between">
                        <div className="flex items-center gap-1.5 text-sm text-gray-800">
                          What is your email?
                        </div>
                      </div>
                      <input
                        className="w-full rounded-md border-gray-300 px-3 py-2 accent-highlight outline-0"
                        placeholder="Email..."
                        type="text"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="submit"
                    className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm"
                  >
                    Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
}

ForgotPasswordModal.propTypes = {
  forgotOpen: PropTypes.bool.isRequired,
  setForgotOpen: PropTypes.func.isRequired
};

export default ForgotPasswordModal;
