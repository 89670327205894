import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { FaChevronLeft } from 'react-icons/fa';

function Modal({
  currentStep,
  setCurrentStep,
  open,
  close,
  title,
  content,
  buttonText,
  onClick,
  buttonText2,
  button2,
  onClick2
}) {
  const prevStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          close();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-black bg-opacity-40 backdrop-blur" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {currentStep > 0 && currentStep < 8 ? (
                  <button
                    className="absolute top-2.5 left-2.5"
                    onClick={prevStep}
                    type="button"
                    aria-label="Go back"
                  >
                    <div className="rounded-md bg-gray-100 p-2.5 text-gray-500 transition hover:bg-gray-200 hover:text-gray-800 active:bg-gray-300">
                      <FaChevronLeft />
                    </div>
                  </button>
                ) : null}

                <div className="flex flex-col justify-center">
                  <div className="text-center">
                    {title ? (
                      <Dialog.Title
                        as="h3"
                        className="my-5 text-xl font-semibold leading-none text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    ) : null}
                    <div className="mt-2">
                      <p className="text-gray-900">
                        {content || 'Something went wrong'}
                      </p>
                    </div>
                  </div>
                </div>
                {(currentStep < 8 && currentStep !== 7) ||
                currentStep === 10 ? (
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="flex justify-center w-full button-gradient"
                      onClick={onClick}
                    >
                      {buttonText || 'Cancel'}
                    </button>
                    {button2 ? (
                      <button
                        type="button"
                        className="mt-2.5 flex w-full justify-center rounded-lg bg-gray-100 py-3.5 text-sm font-medium !text-black transition hover:bg-gray-200 active:bg-gray-300"
                        onClick={onClick2}
                      >
                        {buttonText2 || 'Cancel'}
                      </button>
                    ) : null}
                  </div>
                ) : null}
                {currentStep === 0 || currentStep === 10 ? (
                  <div className="mt-2.5">
                    <button
                      type="button"
                      className="button-gradient flex w-full justify-center !bg-gray-100 !bg-gradient-to-t !text-gray-500 !shadow-none hover:!bg-gray-200 active:!bg-gray-300"
                      onClick={onClick2}
                    >
                      {buttonText2 || 'Cancel'}
                    </button>
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClick2: PropTypes.func.isRequired,
  buttonText2: PropTypes.string.isRequired,
  button2: PropTypes.node.isRequired
};

export default Modal;
