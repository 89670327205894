import React from 'react';

import useAxios from 'axios-hooks';

import { toast } from 'react-hot-toast';

import { useDataContext } from 'context/UserContext';
import { formatPrice } from 'utils/analytics';

function TopCards() {
  const { user } = useDataContext();
  const [{ data: checkoutList = [] }] = useAxios('/analytics/checkouts');

  const checkoutTotal = checkoutList?.reduce((acc, cur) => {
    return acc + cur.price;
  }, 0);

  const checkoutTotalThisMonth = checkoutList?.reduce((acc, cur) => {
    if (new Date(cur.createdAt).getMonth() === new Date().getMonth()) {
      return acc + cur.price;
    }
    return acc;
  }, 0);

  if (checkoutTotalThisMonth > 0) {
    const subscriptionMultiple = Math.ceil(
      checkoutTotalThisMonth / user.subscription.price
    );

    return (
      <div className="rounded-md border bg-white p-5 shadow-sm">
        <div className="mb-2.5 flex items-center gap-1.5 font-bold">
          🙂 Nice job!
        </div>
        <div className="flex flex-col">
          <p className="opacity-95">
            In total, you&apos;ve saved{' '}
            <strong>${formatPrice(checkoutTotal)}</strong> and received{' '}
            <strong>
              {checkoutList?.length} package
              {checkoutList?.length === 1 ? '' : 's'}
            </strong>
            !
          </p>
          {checkoutTotalThisMonth >= user.subscription.price && (
            <p className="opacity-95">
              This month alone you&apos;ve made back your monthly subscription{' '}
              <strong>
                {subscriptionMultiple > 1
                  ? `${subscriptionMultiple} times over!`
                  : '!'}
              </strong>
            </p>
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default TopCards;
