import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { FaExternalLinkAlt } from 'react-icons/fa';

import CartIcon from 'assets/carticon.svg';

import Spinner from './Spinner';

function ProductList({ products, isLoading, isFailed, allProducts }) {
  const [isSeeingEndOfList, setIsSeeingEndOfList] = useState(false);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  // this determines whether the number of products in the list
  // is equal to the number of products in the database (rather, the number of
  // products that match the search query)
  useEffect(() => {
    if (products.length === allProducts.length) {
      setHasReachedEnd(true);
    } else {
      setHasReachedEnd(false);
    }
  }, [allProducts, products]);

  // this detects when the last product is visible and calls the loadMore function
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsSeeingEndOfList(true);
      } else {
        setIsSeeingEndOfList(false);
      }
    });

    const lastProductElement = document.querySelector(
      '.product-list > li:last-child'
    );
    if (lastProductElement) observer.observe(lastProductElement);

    return () => observer.disconnect();
  }, [products]);

  if (isFailed) {
    return (
      <div className="mt-10 flex w-full flex-col items-center justify-center text-gray-800">
        <img
          src={CartIcon}
          className="mt-4 h-[300px] w-[300px]"
          alt="cart icon"
        />
        <div className="mt-10 text-xl tracking-tight">Not much there...</div>
        <div className="mt-10 w-[35vw] text-center text-base tracking-tight">
          {' '}
          Our Warehouse Deal Scanner is currently down. Stay tuned for great
          deals coming soon
        </div>
      </div>
    );
  }

  if (products.length === 0 && isLoading) {
    const loadingProducts = Array.from({ length: 50 }, (_, index) => (
      <li
        key={index}
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-md"
      >
        <div>
          <div className="flex aspect-square w-full items-center justify-center border-b object-cover">
            <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-b-2 border-gray-400" />
          </div>
        </div>
        <div className="flex h-full flex-col justify-between p-5">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex gap-1.5">
                <p>
                  <span className="line-through">$0.00</span>
                </p>
                <p>
                  <span className="font-medium text-green-700">$0.00</span>
                </p>
              </div>

              <p className="text-xs opacity-75">(0.00% off)</p>
            </div>
            <h2 className="my-2.5 text-lg font-medium text-gray-800 line-clamp-3">
              Loading...
            </h2>
          </div>
          <button
            className="mt-2.5 flex w-full items-center justify-center gap-1.5 rounded-md bg-gray-100 px-5 py-3.5 text-xs font-bold uppercase tracking-wide text-gray-600 transition hover:bg-gray-200 hover:text-gray-800 active:bg-gray-300 active:text-black"
            disabled
            type="button"
          >
            Buy now
            <FaExternalLinkAlt className="text-xs" />
          </button>
        </div>
      </li>
    ));

    return (
      <ul className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
        {loadingProducts}
      </ul>
    );
  }

  return (
    <ul className="product-list grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
      {products.map(product => (
        <li
          key={product.title}
          className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-md"
        >
          <div>
            <div className="flex aspect-square w-full items-center justify-center border-b object-cover">
              {isLoading ? (
                <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-b-2 border-gray-400" />
              ) : (
                <img
                  className="aspect-square w-full border-b object-cover"
                  src={product.image}
                  alt={product.title}
                />
              )}
            </div>
          </div>
          <div className="flex h-full flex-col justify-between p-5">
            <div>
              <div className="flex items-center justify-between">
                <div className="flex gap-1.5">
                  <p>
                    <span className="line-through">${product.origPrice}</span>
                  </p>
                  <p>
                    <span className="font-medium text-green-700">
                      ${product.offerPrice}
                    </span>
                  </p>
                </div>

                <p className="text-xs opacity-75">
                  ({product.discount.toFixed(2)}% off)
                </p>
              </div>
              <h2 className="my-2.5 text-lg font-medium text-gray-800 line-clamp-3">
                {product.title}
              </h2>
            </div>
            <button
              className="mt-2.5 flex w-full items-center justify-center gap-1.5 rounded-md bg-gray-100 px-5 py-3.5 text-xs font-bold uppercase tracking-wide text-gray-600 transition hover:bg-gray-200 hover:text-gray-800 active:bg-gray-300 active:text-black"
              onClick={() => window.open(product.addToCart, '_blank')}
              type="button"
            >
              Buy now
              <FaExternalLinkAlt className="text-xs" />
            </button>
          </div>
        </li>
      ))}
      <Spinner visible={!hasReachedEnd && isSeeingEndOfList} />
    </ul>
  );
}

const TProduct = PropTypes.shape({
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  origPrice: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  addToCart: PropTypes.func.isRequired
});

const TProductList = PropTypes.arrayOf(TProduct);

ProductList.propTypes = {
  products: TProductList.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  allProducts: TProductList.isRequired
};

export default ProductList;
