import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useIntercom } from "react-use-intercom";


const AuthSupportModal = ({ supportOpen, setSupportOpen, error, accountEmail }) => {
  const { showNewMessage } = useIntercom();


  const connectToSupport = (e) => {
    e.preventDefault();
    showNewMessage(`Hey! I am currently facing issues connecting my Amazon account! My account is ${accountEmail} and my error is: ${error}`)
    setSupportOpen(false);
  };

  return (
    <Transition.Root show={Boolean(supportOpen)} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => setSupportOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
        </Transition.Child>
        <form onSubmit={connectToSupport} className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <button
                  type="button"
                  onClick={() => setSupportOpen(false)}
                  className="absolute top-[23px] right-5 opacity-25"
                >
                  <FaTimes />
                </button>
                <div className="mb-5 w-full">
                  <h3 className="leading-none">Error connecting your Amazon account: {accountEmail}</h3>

                  <p className="mt-2 text-red-500">{error}</p>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-between">
                <button
                    type="button"
                    onClick={() => setSupportOpen(false)}
                    className="flex items-center gap-1.5 rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex mr-4"
                  >
                    I can figure it out myself
                  </button>
                  <button
                    type="submit"
                    className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm ml-4" 
                  >
                    Ask Support
                  </button>
          
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};

export default AuthSupportModal;
