import React from 'react';

import PropTypes from 'prop-types';

function CategoryList({ categories, selectedCategory, setSelectedCategory }) {
  return (
    <ul className="space-y-2">
      {categories.map(category => (
        <li key={category} className="">
          <button
            className={`ml-0 cursor-pointer text-gray-700 transition-all hover:ml-1 ${
              category === selectedCategory ? 'text-gradient' : ''
            }`}
            onClick={() => {
              setSelectedCategory(
                selectedCategory === category ? '' : category
              );
            }}
            type="button"
          >
            {category}
          </button>
        </li>
      ))}
    </ul>
  );
}

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  setSelectedCategory: PropTypes.func.isRequired
};

export default CategoryList;
