import React from 'react';

import PropTypes from 'prop-types';

function Wrapper({ children }) {
  return (
    <main className="min-h-[calc(100vh-88px)] w-full flex-1 bg-gray-100/75 px-5 lg:px-10">
      <div className="py-6">
        <div className="mx-auto w-full max-w-screen-xl">
          <div className="py-4">
            <div className="flex flex-col gap-12">{children}</div>
          </div>
        </div>
      </div>
    </main>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default Wrapper;
