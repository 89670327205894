/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';

import { useDataContext } from 'context/UserContext';

function UpdateInfo({
  currentEmail,
  setCurrentEmail,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);

  const reg = /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  const { user, updateUser } = useDataContext();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = password => {
    const re = /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return re.test(password);
  };

  useEffect(() => {
    if (newPassword === '') {
      setPasswordValid(true);
      return;
    }
    if (reg.test(newPassword)) {
      if (newPassword === confirmPassword) {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
    } else {
      setPasswordValid(false);
    }
  }, [newPassword, confirmPassword]);

  const onSubmit = () => {
    if (!passwordValid) {
      toast.error(
        'Password must be at least 8 characters long and contain at least one uppercase letter and one number'
      );
      return;
    }

    const body = {
      oldPassword
    };
    if (currentEmail) body.username = currentEmail;
    if (newPassword && newPassword !== oldPassword) body.password = newPassword;

    axios('/user', {
      method: 'POST',
      data: body
    })
      .then(() => {
        updateUser();
        toast.success('Account successfully updated!');
        setConfirmPassword('');
        setNewPassword('');
        setCurrentEmail('');
        return <Navigate to="/dashboard" replace />;
      })
      .catch(e => {
        const error = e?.response?.data?.error;
        toast.error(error ?? 'Something went wrong!');
      });
  };

  return (
    <div className="overflow-hidden rounded-md">
      <div className="gradient z-[600] h-1 w-full rounded-t-lg" />
      <div className="mb-10 bg-white p-5 shadow-md">
        <div className="mb-6">
          <div className="text-lg font-medium">Update account information</div>
          <p className="text-sm">
            View or update information about your FreebieFlow account such as
            your email and password.
          </p>
        </div>
        <div>
          <form
            className="space-y-6"
            onSubmit={e => {
              e.preventDefault();
              const data = Object.fromEntries(new FormData(e.target));
              onSubmit(data);
            }}
          >
            <div className="flex flex-col gap-5 sm:flex-row">
              <div className="w-full">
                <label
                  htmlFor="current-password"
                  className="block text-sm font-medium text-gray-800"
                >
                  Current password
                </label>
                <div className="mt-1 w-full">
                  <input
                    name="oldPassword"
                    type="password"
                    value={oldPassword}
                    placeholder="********"
                    onChange={e => {
                      setOldPassword(e.target.value);
                    }}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                    id="current-password"
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-800"
                >
                  Change email address
                </label>
                <div className="mt-1 w-full">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={currentEmail}
                    placeholder={user.username}
                    onChange={e => {
                      setCurrentEmail(e.target.value);
                    }}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                  />
                </div>
                {currentEmail.length > 2 ? (
                  <span className="text-xs font-normal text-rose-500">
                    {!validateEmail(currentEmail) &&
                      'Please enter a valid email address.'}
                  </span>
                ) : null}
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-800"
                >
                  New password
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter a new password"
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    autoComplete="current-password"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight"
                  />
                  <button
                    type="button"
                    className="absolute right-0 px-3 py-1"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <FaEyeSlash className="text-gray-400" />
                    ) : (
                      <FaEye className="text-gray-400" />
                    )}
                  </button>
                </div>
                {newPassword.length > 2 ? (
                  <span className="text-xs font-normal text-rose-500">
                    {newPassword.length < 8 &&
                      'Password must be at least 8 characters long.'}
                    {newPassword.length >= 8 &&
                      !validatePassword(newPassword) &&
                      'Use at least one uppercase letter and one number.'}
                  </span>
                ) : null}
                {newPassword.length > 0 ? (
                  <div className="mt-2.5 w-full sm:absolute">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-800"
                    >
                      Confirm new password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        autoComplete="current-password"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className={clsx(
                  'button-gradient button-gradient items-center gap-1.5 rounded-md px-4 py-3 text-sm font-medium leading-none tracking-wide text-button-text transition-all lg:inline-flex',
                  passwordValid === false
                    ? 'cursor-not-allowed select-none opacity-50'
                    : 'cursor-pointer opacity-100'
                )}
              >
                Update info
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

UpdateInfo.propTypes = {
  currentEmail: PropTypes.string.isRequired,
  setCurrentEmail: PropTypes.func.isRequired,
  newPassword: PropTypes.string.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired
};

export default UpdateInfo;
