import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo
} from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';

const DataContext = createContext(null);

export function DataProvider({ children }) {
  const [user, updateUserState] = useState(null);
  const location = useLocation();

  async function updateUser() {
    if (Cookies.get('connect.sid') === undefined) {
      updateUserState(undefined);
      return;
    }
    try {
      const response = await axios.get('/user');
      if (response.data) {
        updateUserState(response.data);
      }
    } catch (e) {
      Cookies.remove('connect.sid');
      updateUserState(undefined);
    }
  }

  const value = useMemo(() => ({ user, updateUser }), [user, updateUser]);

  useEffect(() => {
    updateUser();
  }, []);

  if (user !== null) {
    if (
      user &&
      !location.pathname.startsWith('/terms-of-service') &&
      !location.pathname.startsWith('/privacy-policy') &&
      !location.pathname.startsWith('/dashboard') &&
      !location.pathname.startsWith('/home') &&
      !location.pathname.startsWith('/verify_email') &&
      !location.pathname.startsWith('/payment/success') &&
      !location.pathname.startsWith('/payment/return')
    ) {
      return <Navigate to="/dashboard" />;
    }
    return (
      <DataContext.Provider value={value}>{children}</DataContext.Provider>
    );
  }
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useDataContext = () => {
  const data = useContext(DataContext);
  if (!data) {
    throw new Error('You must wrap the app in a provider.');
  }
  return data;
};
