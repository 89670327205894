import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const cn = (...inputs) => twMerge(clsx(inputs));

/**
 * Check if a string contains 6 digits, in the same format as a 2FA code
 * (not a 2FA secret key)
 */
export const is2FACode = inputString => {
  const regex = /^\d{6}$/;
  return regex.test(inputString);
};

export const isSecretKey = inputString => {
  const regex = /^[A-Z\d]{52}$/;
  return regex.test(inputString);
};
