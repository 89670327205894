'use client';

import * as React from 'react';

import * as SliderPrimitive from '@radix-ui/react-slider';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Slider = React.forwardRef(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={clsx(
      'relative flex w-full touch-none select-none items-center',
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-gray-100">
      <SliderPrimitive.Range className="absolute h-full bg-gradient opacity-70" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full bg-gradient transition-colors disabled:pointer-events-none disabled:opacity-50" />
  </SliderPrimitive.Root>
));

Slider.propTypes = {
  className: PropTypes.string
};

Slider.defaultProps = {
  className: null
};

Slider.displayName = SliderPrimitive.Root.displayName;

export default Slider;
