import React, { useState } from 'react';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';

import privacyPolicy from 'assets/privacy-policy.pdf';
import Post from 'landing/components/Post';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PrivacyPolicy() {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: newNumPages }) => {
    setNumPages(newNumPages);
  };

  return (
    <Post
      title="Privacy Policy"
      body={
        <div className="flex flex-col items-center">
          <div className="mb-4 h-80 overflow-auto">
            <Document
              file={privacyPolicy}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <div style={{ height: '100%' }}>
                {[...Array(numPages)].map((_, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </div>
            </Document>
          </div>
        </div>
      }
    />
  );
}

export default PrivacyPolicy;
