const DISCOUNT_PERCENTAGE = 3;
const DISCOUNT_BREAK_POINT = 93;

export function getActualDiscount(discount) {
  if (discount === 0) return 0;

  return discount < DISCOUNT_BREAK_POINT
    ? discount - DISCOUNT_PERCENTAGE
    : discount;
}

export function displayDiscount(discount) {
  if (discount === 0) return 0;

  return discount < DISCOUNT_BREAK_POINT ? discount + 3 : discount;
}
