import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Lottie from 'lottie-react';
import * as OTPAuth from 'otpauth';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { FaEye, FaEyeSlash, FaSyncAlt } from 'react-icons/fa';
import { ThreeDots } from 'react-loading-icons';

import robot from 'assets/robot.json';

import barcodeImg from './barcode.png';
import step2 from './images/step2.png';
import step3 from './images/step3.png';
import Modal from './Modal';

function Steps({ onDone, setAddAccountModal, close, isOpen }) {
  const [showPassword, setShowPassword] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [barcode, setBarcode] = useState('');
  const [TOTP, setTOTP] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [complete, setComplete] = useState(false);
  const [useManualSetup, setUseManualSetup] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (barcode.length > 0) {
      localStorage.setItem(new Date().toISOString().slice(0, 10), barcode);
    }
  }, [barcode]);

  // check localstorage for barcode
  useEffect(() => {
    const localBarcode = localStorage.getItem(
      new Date().toISOString().slice(0, 10)
    );
    if (localBarcode) {
      setBarcode(localBarcode);
    }
  }, []);

  function getTOTP() {
    const newTOTP = new OTPAuth.TOTP({
      issuer: 'Amazon',
      label: 'FreebieFlow',
      algorithm: 'SHA1',
      digits: 6,
      period: 30,
      secret: barcode.replaceAll(' ', '')
    });
    setTOTP(newTOTP.generate());
  }

  const stepsArray = [
    {
      title: "Let's get started",
      content: (
        <p>
          Let’s get your Amazon account linked up! This process usually takes
          about 10 minutes, but if you follow the instructions correctly you’ll
          be receiving boxes at your door very soon!
        </p>
      ),
      buttonText: (
        <span className="flex items-center gap-1.5">
          <FaSyncAlt />
          Sync new Amazon account
        </span>
      ),
      onClick: () => {
        setCurrentStep(1);
      },
      buttonText2: 'I have synced this Amazon account before',
      onClick2: () => {
        setUseManualSetup(true);
        setCurrentStep(10);
      }
    },
    {
      title: 'Step 2: Two Factor Setup',
      content: (
        <p>
          In order to ensure that your Amazon account stays secure, Freebie Flow
          requires the setup of Two Factor authentication. To get started, click
          the “Start Now” button. It will redirect you to Amazon in a new tab,
          and may prompt you to log in.{' '}
          <strong className="mt-2.5 block">
            Once you are logged in on the Amazon page,{' '}
            <span className="underline">switch back to this tab!</span>
          </strong>
        </p>
      ),
      buttonText: 'Start Now',
      onClick: () => {
        window.open(
          'https://www.amazon.com/a/settings/approval/appbackup',
          '_blank'
        );
        setCurrentStep(2);
      }
    },
    {
      title: 'Step 2: Two Factor Setup',
      content: (
        <div className="div">
          <p className="mb-5">
            Did the page in the other tab look something like this?
          </p>
          <img src={step2} alt="" />
        </div>
      ),
      buttonText: 'Yes',
      onClick: () => {
        setCurrentStep(3);
      },
      buttonText2: 'No',
      onClick2: () => {
        setCurrentStep(1);
      }
    },
    {
      title: 'Step 2: Two Factor Setup',
      content: (
        <div className="div">
          <div>
            <p>
              Click <strong> Can’t scan the barcode?</strong>{' '}
            </p>
            <img src={barcodeImg} alt="" />
          </div>
          <p>
            {' '}
            Copy the long code that appears. It should look something like this:
          </p>
          <img src={step3} alt="" className="my-5" />
          <input
            type="text"
            value={barcode}
            onChange={e => setBarcode(e.target.value)}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            placeholder="Paste the code here..."
          />
        </div>
      ),
      buttonText: 'Continue',
      onClick: () => {
        if (barcode.replaceAll(' ', '').match(/^[A-Z2-7]{32,64}$/)) {
          setCurrentStep(4);
        } else {
          toast.error('Please paste the code from Amazon');
        }
      }
    },
    {
      title: 'Step 3: Google Authenticator',
      content: (
        <div>
          <h1 className="font-bold">
            IMPORTANT: YOU WILL BE LOCKED OUT OF AMAZON IF YOU SKIP THIS STEP.
          </h1>

          <p>
            When you need to log in to Amazon, you’ll be able to use the{' '}
            <strong>Google Authenticator</strong> app to generate a six digit
            code. Let’s set this up!
          </p>

          <p className="mt-5">
            Download the Google Authenticator app from the App Store or Play
            Store on your phone. Open it and{' '}
            <strong>click the plus icon</strong> in the bottom right. Then click{' '}
            <strong>Enter a setup key</strong>. Paste in the following key:
          </p>

          <p className="mt-5">
            <strong className="bg-yellow-200 px-2.5 py-1">{barcode}</strong>
          </p>

          <p className="mt-5">
            Then switch back to this tab and click Continue.
          </p>
        </div>
      ),
      buttonText: 'Continue',
      onClick: () => {
        getTOTP();
        setCurrentStep(5);
      }
    },
    {
      title: 'Confirmation',
      content: (
        <div>
          <h1 className="mt-8 font-bold">
            Are you sure you added Google 2FA? You will be locked out of your
            Amazon Account if you skip this step.
          </h1>
        </div>
      ),
      buttonText: 'Yes',
      onClick: () => {
        setCurrentStep(6);
      }
    },
    {
      title: 'Step 4: Two Factor Setup',
      content: (
        <div>
          <p>
            We now need to give Amazon a Six Digit code to enable two factor
            authentication on your account. Please switch to your Amazon tab and{' '}
            <strong>enter the following code</strong> into the text box on
            Amazon. Then click <strong>“Verify OTP and Continue”</strong> . Once
            that’s done, switch back to this tab and click{' '}
            <strong>Continue</strong> below.
          </p>

          <strong className="block my-5 text-4xl">{TOTP}</strong>
          <p className="text-sm">
            You can also get this code from the Google Authenticator App.
          </p>
        </div>
      ),
      buttonText: 'Continue',
      onClick: () => {
        setCurrentStep(7);
      }
    },
    {
      title: 'Step 5: Account Linking',
      content: (
        <form
          onSubmit={e => {
            e.preventDefault();
            if (email.length > 3 && password.length > 3) {
              setCurrentStep(8);
            } else {
              toast.error('Please enter your email and password');
            }
          }}
        >
          <p>
            Almost done! Freebie Flow now requires your Amazon account email and
            password so that it can check out items on your behalf.{' '}
          </p>
          <p>
            All account information is encrypted and securely stored in the
            cloud.
          </p>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="mt-5 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            placeholder="Email..."
            required
          />
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="mt-2.5 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            placeholder="Password..."
            required
          />
          <button
            type="submit"
            className="flex justify-center w-full mt-5 button-gradient"
          >
            Submit
          </button>
        </form>
      )
    },
    {
      content: (
        <div>
          <div className="flex flex-col items-center gap-2.5">
            <ThreeDots fill="rgba(0,0,0,0.3)" width="50px" />
            <div className="text-xl font-semibold leading-none text-gray-900">
              Linking your account...
            </div>

            <p className="text-center">
              Please wait while we connect to your Amazon account.
            </p>
          </div>
        </div>
      )
    },
    {
      content: (
        <div>
          <div className="mx-auto -mt-9 w-60">
            <Lottie animationData={robot} loop />
          </div>

          <p className="mb-1.5 text-xl font-semibold">
            Thank you! Attemping log in...
          </p>
          <p className="px-5 text-sm opacity-60">
            Please allow 1-3 minutes for full connection. You can click continue
            at any time.
          </p>
          <div className="p-5 mt-5 text-sm text-left text-gray-700 border rounded-lg shadow">
            <h3 className="mb-2.5">Tips for success</h3>
            <p>
              • Expect at least 10 freebies a month, with many users getting
              their first within a week.
            </p>
            <p className="mt-2">
              • If you want more than just freebies, you can edit your account
              settings to set custom discount percentages and dollar amounts.
            </p>
            <p className="mt-2">
              • Contact customer support by pressing the widget in the bottom
              right anytime you need help.
            </p>
          </div>
          <button
            type="button"
            className="mt-5 flex w-full justify-center rounded-lg bg-gray-100 py-3.5 text-sm font-medium !text-black transition hover:bg-gray-200 active:bg-gray-300"
            onClick={() => {
              window.history.pushState({}, '', '/dashboard');
              window.location.reload();
              setComplete(true);
            }}
          >
            Continue
          </button>
        </div>
      )
    },
    {
      title: 'Manual Configuration',
      content: (
        <div className="grid gap-2.5">
          <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
            <div className="w-full">
              <div className="mb-2.5 flex w-full items-center justify-between">
                <div className="flex items-center gap-1.5 text-sm text-gray-800">
                  Amazon Account Email/Number
                </div>
              </div>
              <input
                className="w-full px-3 py-2 border-gray-300 rounded-md accent-highlight outline-0"
                placeholder="Email/Number"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
            <div className="w-full">
              <div className="mb-2.5 flex w-full items-center justify-between">
                <div className="flex items-center gap-1.5 text-sm text-gray-800">
                  Amazon Account Password
                </div>
              </div>
              <div className="relative flex items-center">
                <input
                  className="w-full px-3 py-2 border-gray-300 rounded-md accent-highlight outline-0"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 py-1"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <FaEyeSlash className="text-gray-400" />
                  ) : (
                    <FaEye className="text-gray-400" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
            <div className="w-full">
              <div className="mb-2.5 flex w-full items-center justify-between">
                <div className="flex items-center gap-1.5 text-sm text-gray-800">
                  2FA Secret
                </div>
              </div>
              <div className="relative flex items-center">
                <input
                  className="w-full px-3 py-2 border-gray-300 rounded-md accent-highlight outline-0"
                  placeholder="2FA Secret"
                  type="text"
                  value={barcode}
                  onChange={e => {
                    setBarcode(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ),
      buttonText: 'Submit',
      onClick: () => {
        setCurrentStep(8);
      },
      buttonText2: 'Switch to Guided Configuration',
      onClick2: () => {
        setUseManualSetup(false);
        setCurrentStep(0);
      }
    }
  ];

  useEffect(() => {
    if (currentStep === 4) {
      setInterval(() => {
        getTOTP();
      }, 5000);

      // TODO: POST barcode state to server here -
      //   Might not be necessary? OTPs generated client side.
    } else if (currentStep === 8) {
      // POST email and password to server here
      // On success, setCurrentStep(8) here
      // On failure, setCurrentStep(6), toast.error("Incorrect email or password") here
      axios('/accounts', {
        method: 'PUT',
        data: {
          email, // TODO: Validation on email and password maybe?
          password,
          twoFactorKey: barcode,
          isDealAccount: false, // Do we still have deal accounts?
          controls: {
            discount: 100,
            minPrice: 0,
            maxPrice: 1
          }
        }
      })
        .then(() => {
          setCurrentStep(9);
        })
        .catch(e => {
          try {
            if (e.response.data.error) {
              toast.error(e.response.data.error);
            } else {
              toast.error('Incorrect email or password!');
            }
          } catch (e2) {
            toast.error('Incorrect email or password!');
          }

          if (useManualSetup) {
            setCurrentStep(10);
          } else {
            setCurrentStep(7);
          }

          console.log(e);
        });
    }
  }, [currentStep]);

  if (complete) {
    setAddAccountModal(false);
    window.history.pushState({}, '', '/dashboard');
    onDone();
  }

  return (
    <Modal
      complete={complete}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      title={stepsArray[currentStep].title}
      content={stepsArray[currentStep].content}
      close={close}
      open={isOpen}
      buttonText={stepsArray[currentStep].buttonText}
      onClick={stepsArray[currentStep].onClick}
      buttonText2={stepsArray[currentStep].buttonText2}
      onClick2={stepsArray[currentStep].onClick2}
    />
  );
}

Steps.propTypes = {
  onDone: PropTypes.func.isRequired,
  setAddAccountModal: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default Steps;
