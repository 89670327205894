import {
  FaCheckCircle,
  FaHeadset,
  FaMinusCircle,
  FaPiggyBank
} from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { BsStars } from 'react-icons/bs';
import { FaCog } from 'react-icons/fa';
import { stripePublishableKey } from '../../config';
import { loadStripe } from '@stripe/stripe-js';
import Tiktok from '../../landing/components/TikTok';
import Testimonials from '../../landing/sections/Testimonials';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

const plans = [
  {
    name: 'Starter',
    botFeatures: [
      {
        available: true,
        text: 'Sync 1 Amazon Account'
      },
      {
        available: true,
        text: 'Access to standard deals & freebies'
      },
      {
        available: false,
        text: 'Access to lightning deals ⚡️'
      }
    ],
    communityFeatures: [
      'Access to telegram community',
      '24/7 dedicated support'
    ],
    estSavings: '$350',
    estPackages: '10',
    price: {
      monthly: {
        amount: 59,
        stripePriceId: 'price_1O46aoLVMiFmGBXg7AG5tPJ6'
      },
      annually: {
        amount: 50,
        stripePriceId: 'price_1O46aoLVMiFmGBXgC4LVqRt5'
      }
    },
    firstMonth: 49
  },
  {
    name: 'Premium',
    botFeatures: [
      {
        available: true,
        text: (
          <>
            Sync <strong className="font-semibold">2 Amazon Accounts</strong>
          </>
        )
      },
      {
        available: true,
        text: 'Access to standard deals & freebies'
      },
      {
        available: true,
        text: 'Access to lightning deals ⚡️'
      }
    ],
    communityFeatures: [
      'Access to telegram community',
      '24/7 dedicated support'
    ],
    estSavings: '$850',
    estPackages: '25',
    price: {
      monthly: {
        amount: 99,
        stripePriceId: 'price_1O46fRLVMiFmGBXgzQs7RGZb'
      },
      annually: {
        amount: 79,
        stripePriceId: 'price_1O46fRLVMiFmGBXghHoE6O0D'
      }
    },
    firstMonth: 89
  }
];

const TrustpilotLogo = ({ className }) => (
  <img alt="trustpilot logo" className={className} src="/trustpilot-logo.svg" />
);

const proofingInfo = [
  {
    id: 1,
    Image: TrustpilotLogo,
    title: '110+ Five-Star Reviews',
    description:
      'We have many satisfied customers who are enjoying massive savings and freebies.'
  },
  {
    id: 2,
    Icon: FaPiggyBank,
    title: '100% Satisfaction Guarantee',
    description:
      "We think you'll love FreebieFlow. But if you don't, you can get in touch within 30 days for a full refund."
  },
  {
    id: 3,
    Icon: FaHeadset,
    title: '24/7 Live Chat Support',
    description:
      'If you ever need help using our service, you can talk to us via live chat at any time.'
  }
];

const stripePromise = loadStripe(stripePublishableKey);

const Plans = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedPlan) return;

    setLoading(true);
    axios('/payembed', {
      method: 'POST',
      data: {
        priceId: selectedPlan,
        returnUrl: window.location.origin + '/payment/return'
      }
    })
      .then(res => {
        setClientSecret(res.data.clientSecret);
        setLoading(false);
      })
      .catch(e => {
        const error = e?.response?.data?.error;
        console.error(error ?? 'Something went wrong!');
      });
  }, [selectedPlan]);

  useEffect(() => {
    if (clientSecret) {
      setTimeout(() => {
        const freebieCheckout = document.getElementById('freebie-checkout');
        freebieCheckout.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }, 350);
    }
  }, [clientSecret, selectedPlan]);

  return (
    <div className="relative">
      <div className="w-full absolute p-5 justify-between flex z-50">
        <Link
          to="/home"
          className="flex items-center gap-1.5 text-xl font-medium lg:w-1/3"
        >
          <img src={logo} alt="" className="w-48" />
        </Link>
     
      </div>
      <div className="flex min-h-screen flex-col items-center overflow-clip px-5 pt-32">
        <div className="blur-2xl">
          <div className="absolute left-64 top-60 w-[400px] -rotate-90 opacity-75 lg:w-[400px]">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#fce7f3"
                d="M29.1,-52C35.4,-41.1,36.7,-29.3,41.9,-18.9C47.1,-8.5,56.1,0.4,59.1,11.4C62,22.4,59,35.4,51,43.8C43.1,52.2,30.3,56,17.9,58.7C5.4,61.4,-6.8,62.9,-21.3,63C-35.8,63.1,-52.7,61.7,-61.8,52.7C-70.9,43.7,-72.3,27.2,-72.4,11.7C-72.6,-3.7,-71.6,-18,-63.9,-26.9C-56.2,-35.7,-41.8,-39.2,-30.1,-47.6C-18.3,-56,-9.1,-69.2,1.1,-71C11.4,-72.7,22.7,-62.9,29.1,-52Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
          <div className="absolute -right-60 -top-52 w-[400px] opacity-75 lg:w-[800px]">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#fce7f3"
                d="M45.7,-75.4C55.9,-64.6,58.5,-46.2,64.8,-30.2C71,-14.2,81.1,-0.6,79.3,11.3C77.5,23.3,63.9,33.4,51.1,39.3C38.3,45.3,26.3,47,15.1,49.1C4,51.3,-6.3,53.8,-20.3,55.8C-34.2,57.7,-51.9,59,-64.7,51.8C-77.4,44.7,-85.3,29,-79.5,16.3C-73.7,3.6,-54.3,-6.2,-42.7,-14.5C-31.1,-22.9,-27.4,-29.9,-21.6,-42.7C-15.8,-55.5,-7.9,-74.2,4.9,-81.9C17.8,-89.5,35.5,-86.3,45.7,-75.4Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </div>
        <div className="mb-10 lg:text-center">
          <p
            data-aos="fade-up"
            className="mt-2 text-center text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl"
          >
            You have Freebies waiting for you!
          </p>
          <p
            data-aos="fade-up"
            data-aos-delay="100"
            className="mt-5 max-w-xl text-center text-gray-600 mx-auto text-lg lg:text-xl lg:leading-relaxed"
          >
            Subscribe now to start getting{' '}
            <b>free stuff delivered right to your door</b>. Easily cancel at any
            time, no questions asked!
          </p>
        </div>

        <section className="w-screen mt-10">
          <div className="bg-gray-50 px-4 sm:px-12 py-12 rounded-[54px] max-w-screen-xl mx-auto">
            <div className="mx-auto w-full gap-5 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
              <div className="xl:mt-0 xl:p-5 w-full xl:w-80 flex-shrink-0 z-10 gap-14 flex flex-col lg:flex-row xl:flex-col items-center justify-center lg:col-span-2 xl:col-span-1 my-10 xl:my-0">
                {proofingInfo.map(({ id, title, description, Icon, Image }) => (
                  <div
                    className="text-center"
                    key={id}
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    {Icon && (
                      <Icon
                        size={40}
                        className="mx-auto mb-5 text-indigo-500"
                      />
                    )}
                    {Image && <Image className="mx-auto mb-5 h-10" />}
                    <h3 className="mb-3 text-gray-900">{title}</h3>
                    <p className="text-gray-500">{description}</p>
                  </div>
                ))}
              </div>

              <div className="lg:order-first lg:col-span-2 xl:col-span-3">
                <div
                  data-aos="fade-up"
                  data-aos-delay="150"
                  class="flex justify-center mb-5 xl:mb-10"
                >
                  <div class="grid grid-cols-2 rounded-full bg-white p-1 text-center text-sm leading-5 shadow-md">
                    <button
                      onClick={() => setIsAnnual(false)}
                      class={clsx(
                        'cursor-pointer rounded-full px-2.5 py-1 font-semibold',
                        isAnnual === false && 'bg-gray-200 '
                      )}
                    >
                      <span>Monthly</span>
                    </button>

                    <button
                      onClick={() => setIsAnnual(true)}
                      class={clsx(
                        'cursor-pointer rounded-full px-2.5 py-1 font-semibold',
                        isAnnual === true
                          ? 'bg-gradient text-white'
                          : 'text-gradient'
                      )}
                    >
                      <span>Yearly</span>
                    </button>
                  </div>
                </div>
              </div>

              {plans.map((plan, index) => {
                const isPremium = plan.name === 'Premium';

                return (
                  <div
                    data-aos="fade-up"
                    data-aos-delay={250 + index * 50}
                    className={clsx(
                      'mx-auto flex w-full rounded-[27px] p-1 shadow-lg',
                      isPremium ? 'bg-gradient' : 'bg-white'
                    )}
                  >
                    <div
                      key={index}
                      className={clsx(
                        'flex w-full flex-col justify-between rounded-3xl  bg-white p-5 text-[#222]'
                      )}
                    >
                      <div>
                        <h3 className="relative mb-2.5 mr-auto inline-flex items-center gap-1 text-base font-bold uppercase tracking-wide">
                          {isPremium ? (
                            <BsStars className="text-[#ED567D]" />
                          ) : null}{' '}
                          <span className={clsx(isPremium && 'text-gradient')}>
                            {plan.name}
                          </span>{' '}
                          <span className="!bg-transparent font-normal !text-[#222]">
                            FLOW
                          </span>
                        </h3>
                        <div className="flex items-end text-5xl font-medium tracking-tight lg:text-5xl text-gray-700">
                          $
                          {isAnnual
                            ? plan.price.annually.amount - 10
                            : plan.price.monthly.amount - 10}
                          <div className="relative bottom-1.5 ml-1.5 text-base font-normal tracking-normal">
                            / month
                          </div>
                        </div>
                        <p className="mt-2.5 text-sm opacity-80">
                          for your first month, then $
                          {isAnnual
                            ? plan.price.annually.amount
                            : plan.price.monthly.amount}{' '}
                          / month
                        </p>
                        <div className="flex gap-2.5 my-8">
                          <div className="relative flex aspect-square w-full flex-col items-center justify-center rounded-xl border bg-gray-100 p-2.5 max-w-[150px] max-h-[150px]">
                            <div
                              className={clsx(
                                'text-3xl font-bold lg:text-4xl',
                                isPremium && 'text-gradient'
                              )}
                            >
                              {plan.estPackages}+
                            </div>
                            <p className="mt-0.5 text-center text-sm font-medium">
                              estimated freebies per month
                            </p>
                          </div>
                          <div className="relative flex aspect-square w-full flex-col items-center justify-center rounded-xl border bg-gray-100 p-2.5  max-w-[150px] max-h-[150px]  ">
                            <div
                              className={clsx(
                                'text-3xl font-bold lg:text-4xl',
                                isPremium && 'text-gradient'
                              )}
                            >
                              {plan.estSavings}+
                            </div>
                            <p className="mt-1.5 text-center text-sm font-medium">
                              estimated savings per month
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="space-y-1.5">
                        <div className={clsx('flex flex-col gap-1.5')}>
                          <h3 className="text-base font-bold">Bot Features</h3>
                          {plan.botFeatures?.map(feature => {
                            return (
                              <div
                                className={clsx(
                                  'flex shrink-0 items-baseline gap-1.5 text-sm md:text-base text-gray-700',
                                  isPremium && ''
                                )}
                              >
                                {feature.available ? (
                                  <FaCheckCircle
                                    className={clsx(
                                      'relative top-0.5 shrink-0 text-green-500'
                                    )}
                                  />
                                ) : (
                                  <FaMinusCircle
                                    className={clsx(
                                      'relative top-0.5 shrink-0 text-red-500'
                                    )}
                                  />
                                )}

                                {feature.text}
                              </div>
                            );
                          })}
                        </div>
                        <div className={clsx('flex flex-col gap-1.5')}>
                          <h3 className="text-base font-bold mt-2">
                            Community Features
                          </h3>
                          {plan.communityFeatures?.map(feature => {
                            return (
                              <div
                                className={clsx(
                                  'flex shrink-0 items-baseline gap-1.5 text-sm md:text-base text-gray-700',
                                  isPremium && ''
                                )}
                              >
                                <FaCheckCircle
                                  className={clsx(
                                    'relative top-0.5 shrink-0 text-green-500'
                                  )}
                                />
                                {feature}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <button
                        onClick={() => {
                          if (
                            selectedPlan !==
                            plan.price[isAnnual ? 'annually' : 'monthly']
                              .stripePriceId
                          )
                            setClientSecret(null);
                          setSelectedPlan(
                            plan.price[isAnnual ? 'annually' : 'monthly']
                              .stripePriceId
                          );
                        }}
                        className={clsx(
                          'mx-auto mt-4 !block w-full items-center !rounded-full px-5 py-3 !text-sm !font-bold !uppercase tracking-wide text-white transition',
                          isPremium
                            ? 'button-gradient'
                            : 'bg-[#222] hover:bg-black hover:text-white'
                        )}
                      >
                        Get {plan.name}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <div
          id="freebie-checkout"
          className="mx-auto w-full max-w-[1000px] mt-12 xl:mt-24"
        >
          {selectedPlan && clientSecret ? (
            <div className="w-full rounded-3xl border bg-white py-5 shadow-md lg:py-14">
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret }}
              >
                <div className="w-5xl">
                  <EmbeddedCheckout />{' '}
                </div>
              </EmbeddedCheckoutProvider>{' '}
            </div>
          ) : null}
        </div>
      </div>
      <section className="mx-auto flex max-w-screen-xl flex-col items-center px-5">
        <Tiktok
          style={{
            background: 'white'
          }}
        />
      </section>
      <section className="mx-auto mb-20 flex max-w-screen-xl flex-col items-center px-5">
        <Testimonials />
      </section>
    </div>
  );
};

export default Plans;