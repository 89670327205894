import React, { useState } from 'react';

import {
  FaChevronRight,
  FaHeart,
  FaPlay,
  FaTiktok,
  FaUser
} from 'react-icons/fa';
import { IoSparklesSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

function TikTok() {
  const urls = [
    {
      url: 'https://cdn.discordapp.com/attachments/1074080148263030805/1164008919585276044/couponingwithtina.mp4?ex=6541a6c8&is=652f31c8&hm=08f9da76e097988c8c1d37bf37d5913ba344ec8262a4ef37127449efc9738e34&',
      avatar:
        'https://cdn.discordapp.com/attachments/1074080148263030805/1165716441530630154/76cc98235655d70ffd904adb07a38c9ac5_100x100.jpeg?ex=6547dd09&is=65356809&hm=e5f77b10364ee7fb4d3e75d12420218e3f8648eef5a1d2cca5d5c8c36786286d&',
      username: 'couponingwithtina',
      followers: '236.4K',
      likes: '1.1M',
      link: 'https://www.tiktok.com/@couponingwithtina/video/7243552051454594347?embed_source=121355058%2C121351166%2C121331973%2C120811592%2C120810756%3Bnull%3Bembed_pause_share&refer=embed&referer_url=freebieflow.com%2F&referer_video_id=7243552051454594347',
      poster: '/tiktok-thumbnail/video-one.webp'
    },
    {
      url: 'https://cdn.discordapp.com/attachments/1074080148263030805/1165715717379850260/Download_1.mp4?ex=6547dc5c&is=6535675c&hm=44f1e44cd38ebc73492459a7cae9aadf2d9cd5a74f03d3c05142db2b51c01e19&',
      avatar:
        'https://media.discordapp.net/attachments/1074080148263030805/1165715783024910406/64d855e837d80a6222d4dab95fd821dbc5_100x100.jpeg?ex=6547dc6c&is=6535676c&hm=42a91584a67f10d7593c86d7509fdbaedec90ec69e56afde58eefe0ef2528c53&=',
      username: 'dailydealswithpaige',
      followers: '210.1K',
      likes: '1.1M',
      link: 'https://www.tiktok.com/@dailydealswithpaige/video/7272138785817775402',
      poster: '/tiktok-thumbnail/video-two.jpg'
    },
    {
      url: 'https://cdn.discordapp.com/attachments/1074080148263030805/1164008917890777218/savingwithkitty.mp4?ex=6541a6c8&is=652f31c8&hm=38219e294580dc3d64d86c133759ba91aa5fe9cdedc53d4a67128de1517cd76a&',
      avatar:
        'https://media.discordapp.net/attachments/1074080148263030805/1164008914921209946/savingwithkitty.jpeg?ex=6541a6c7&is=652f31c7&hm=0d56fd8c9d601636d48f97f20a40ed0a791f9b673505aa6febbf3a2c76c7c397&=&width=200&height=200',
      username: 'savingwithkitty',
      followers: '26.8K',
      likes: '71.3K',
      link: 'https://www.tiktok.com/@savingwithkitty/video/7256250956940053806?embed_source=121355058%2C121351166%2C121331973%2C120811592%2C120810756%3Bnull%3Bembed_share&refer=embed&referer_url=freebieflow.com%2F&referer_video_id=7256250956940053806',
      poster: '/tiktok-thumbnail/video-three.webp'
    },

    {
      url: 'https://cdn.discordapp.com/attachments/1074080148263030805/1164008918708658176/bodykintsugi.mp4?ex=6541a6c8&is=652f31c8&hm=f8793e673d83f592357824f449c83861405c01ba83ff95896c7ae92a340fbe35&',
      avatar:
        'https://cdn.discordapp.com/attachments/1074080148263030805/1164008915550351511/bodykintsugi.jpeg?ex=6541a6c7&is=652f31c7&hm=a8a60704ea4e41833a91cb1b7344aeaac301931c55a633b0969a492255877d51&',
      username: 'bodykintsugi',
      followers: '6951',
      likes: '73.6K',
      link: 'https://www.tiktok.com/@bodykintsugi/video/7245126151302057258?embed_source=121355058%2C121351166%2C121331973%2C120811592%2C120810756%3Bnull%3Bembed_pause_share&refer=embed&referer_url=freebieflow.com%2F&referer_video_id=7245126151302057258',
      poster: '/tiktok-thumbnail/video-four.jpg'
    }
  ];

  const [playingVideo, setPlayingVideo] = useState(null);

  const playVideo = videoElement => {
    if (playingVideo) {
      playingVideo.pause();
    }
    videoElement.play();
    setPlayingVideo(videoElement);
  };

  return (
    <section className="bd-gradient wrapper">
      <div className="container">
        <h2 className="mx-auto flex flex-col items-center justify-center gap-2.5 pb-8 text-center text-3xl font-semibold lg:flex-row lg:text-4xl">
          <IoSparklesSharp className="hidden text-5xl lg:mb-0 lg:block lg:text-4xl" />{' '}
          <span>
            See <span className="text-gradient">FreebieFlow</span> in action{' '}
          </span>
        </h2>
        <div className="mt-10 hidden gap-5 md:grid md:grid-cols-2 xl:grid-cols-4">
          {urls.map(item => (
            <div
              className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-3xl shadow-lg"
              key={item.username}
            >
              <video
                src={item.url}
                preload="metadata"
                poster={item.poster}
                className="h-auto w-full desktop"
                onClick={e => {
                  if (playingVideo === e.target) {
                    playingVideo.pause();
                    setPlayingVideo(null);
                  } else {
                    playVideo(e.target);
                  }
                }}
              />

              <div className="relative z-50 flex w-full flex-col items-center bg-white p-5">
                <a
                  href={item.link.substring(0, item.link.indexOf('/video'))}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mb-5 flex items-center gap-2.5"
                >
                  <img
                    src={item.avatar}
                    alt="tiktok avatar"
                    className="h-12 w-12 rounded-full border-2 border-white"
                  />
                  <div className="text-lg font-bold">{item.username}</div>
                </a>

                <div className="mb-7 flex w-full justify-evenly gap-5">
                  <div className="flex flex-col items-center text-center">
                    <div className="flex items-center gap-1.5 font-bold">
                      <FaUser className="text-xs text-rose-500" />
                      {item.followers}
                    </div>
                    <div>followers</div>
                  </div>
                  <div className="flex flex-col items-center text-center">
                    <div className="flex items-center gap-1.5 font-bold">
                      <FaHeart className="text-xs text-rose-500" />
                      {item.likes}
                    </div>
                    <div>likes</div>
                  </div>
                </div>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="flex w-full items-center justify-center gap-2.5 rounded-full bg-gray-100  px-5 py-2.5 text-center text-sm font-semibold transition hover:bg-black hover:text-white"
                >
                  <FaTiktok /> Watch on TikTok
                </a>
              </div>

              {!playingVideo || playingVideo.src !== item.url ? (
                <button
                  onClick={() => {
                    // find the video element by url, and play it
                    const videoElement = document.querySelector(
                      `video[src="${item.url}"]:is(.desktop)`
                    );
                    playVideo(videoElement);
                  }}
                  type="button"
                  aria-label="Play"
                  className="absolute top-[calc(50%-112px)] left-1/2 z-30 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform cursor-pointer items-center justify-center border-none bg-transparent focus:outline-none"
                >
                  <div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/40 shadow-md backdrop-blur-md transition hover:bg-black/60">
                    <FaPlay className="text-lg text-white" />
                  </div>
                </button>
              ) : null}
            </div>
          ))}
        </div>
        <div className="mt-10 grid gap-5 md:hidden md:grid-cols-2 xl:grid-cols-4">
          {urls
            .map(item => (
              <div
                className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-3xl shadow-lg"
                key={item.username}
              >
                <video
                  src={item.url}
                  preload="metadata"
                  poster={item.poster}
                  className="h-[400px] object-cover w-full mobile"
                  onClick={e => {
                    if (playingVideo === e.target) {
                      playingVideo.pause();
                      setPlayingVideo(null);
                    } else {
                      playVideo(e.target);
                    }
                  }}
                />
                <div className="relative z-50 flex w-full flex-col items-center bg-white p-5">
                  <a
                    href={item.link.substring(0, item.link.indexOf('/video'))}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="mb-5 flex items-center gap-2.5"
                  >
                    <img
                      src={item.avatar}
                      alt="tiktok avatar"
                      className="h-12 w-12 rounded-full border-2 border-white"
                    />
                    <div className="text-lg font-bold">{item.username}</div>
                  </a>

                  <div className="mb-7 flex w-full justify-evenly gap-5">
                    <div className="flex flex-col items-center text-center">
                      <div className="flex items-center gap-1.5 font-bold">
                        <FaUser className="text-xs text-rose-500" />
                        {item.followers}
                      </div>
                      <div>followers</div>
                    </div>
                    <div className="flex flex-col items-center text-center">
                      <div className="flex items-center gap-1.5 font-bold">
                        <FaHeart className="text-xs text-rose-500" />
                        {item.likes}
                      </div>
                      <div>likes</div>
                    </div>
                  </div>
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="flex w-full items-center justify-center gap-2.5 rounded-full bg-gray-100  px-5 py-2.5 text-center text-sm font-semibold transition hover:bg-black hover:text-white"
                  >
                    <FaTiktok /> Watch on TikTok
                  </a>
                </div>

                {!playingVideo || playingVideo.src !== item.url ? (
                  <button
                    onClick={() => {
                      // find the video element by url, and play it
                      const videoElement = document.querySelector(
                        `video[src="${item.url}"]:is(.mobile)`
                      );
                      playVideo(videoElement);
                    }}
                    type="button"
                    aria-label="Play"
                    className="absolute top-[calc(50%-112px)] left-1/2 z-30 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform cursor-pointer items-center justify-center border-none bg-transparent focus:outline-none"
                  >
                    <div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/40 shadow-md backdrop-blur-md transition hover:bg-black/60">
                      <FaPlay className="text-lg text-white" />
                    </div>
                  </button>
                ) : null}
              </div>
            ))
            .slice(0, 1)}
        </div>
        <div className="flex w-full justify-center pt-20">
          <Link
            to="/dashboard/register"
            className="button-gradient mx-auto flex justify-center !rounded-full"
          >
            Start getting freebies now! <FaChevronRight className="text-xs" />
          </Link>
        </div>
        <p className="mt-2.5 text-xs leading-5 text-gray-600 text-center w-full">
          Easily cancel at any time, no questions asked.
        </p>
      </div>
    </section>
  );
}

export default TikTok;
