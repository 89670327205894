import React from 'react';

import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import logo from 'assets/logo.png';

const navigation = {
  main: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Accessibility', href: '#' },
    { name: 'Partners', href: '#' }
  ],
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/freebie_flow/',
      icon: <FaInstagram />
    },

    {
      name: 'Twitter',
      href: 'https://twitter.com/Freebie_Flow',
      icon: <FaTwitter />
    },
    {
      name: 'Youtube',
      href: 'https://www.youtube.com/@freebieflow/featured',
      icon: <FaYoutube />
    }
  ]
};

export default function Footer() {
  return (
    <footer id="footer" className="bg-background">
      <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => {
            const section = document.getElementById('hero');
            section.scrollIntoView({ behavior: 'smooth' });
          }}
          type="button"
        >
          <img
            src={logo}
            className="mx-auto w-48 cursor-pointer"
            alt="Logo"
            title="Scroll back to the top?"
          />
        </button>
        <div className="mt-12 flex justify-center space-x-6">
          {navigation.social.map(item => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-500 hover:text-gray-700"
            >
              <span className="sr-only">{item.name}</span>
              {item.icon}
            </a>
          ))}
        </div>
        <div className="mt-12 flex items-center justify-center gap-5 text-xs font-light text-gray-500">
          <Link
            className="font-medium tracking-wide transition"
            to="/terms-of-service"
          >
            Terms of Service
          </Link>
          <Link
            className="font-medium tracking-wide transition"
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-center border-t border-gray-200 bg-white py-3 text-xs font-light text-gray-500">
        &copy; {new Date().getFullYear()} FreebieFlow. All rights reserved.
      </div>
    </footer>
  );
}
