import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Lottie from 'lottie-react';
import * as OTPAuth from 'otpauth';
import { FaInfoCircle } from 'react-icons/fa';

import robot from 'assets/robot.json';
import { useDataContext } from 'context/UserContext';

import Card from './Card';
import {
  SecretKeyExample,
  SecretKeyExampleContainer
} from './SecretKeyExample';
import SignIn from './SignIn';

export default function Guide() {
  const { user } = useDataContext();
  const { hasSeenOnboarding } = user;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [manualSetup, setManualSetup] = useState(false);
  const [code, setCode] = useState('');
  const [codeValid, setCodeValid] = useState(false);
  const [, setTOTP] = useState('');

  useEffect(() => {
    try {
      let refurl = 'none';
      if (window?.frames?.top?.document?.referrer) {
        refurl = window.frames.top.document.referrer;
      }
      window.posthog.capture('user_started_syncing_account', {
        referrer: refurl
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const goBackward = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(prevStep => prevStep - 1);
    }
  }, [currentStep]);

  const skipToSignIn = useCallback(() => {
    setCurrentStep(hasSeenOnboarding ? 5 : 6);
  }, [hasSeenOnboarding]);

  const steps = useMemo(
    () => [
      ...(hasSeenOnboarding
        ? []
        : [
            {
              title: 'Welcome to FreebieFlow',
              description: (
                <div>
                  We&apos;re excited to have you in the community! Follow these
                  steps to get set up and make the most out of your
                  subscription.
                  <h4 className="mt-5 mb-0 font-medium">
                    First, please confirm the following:
                  </h4>
                </div>
              ),
              buttonLabel: 'Get Started',
              list: [
                <>
                  Your Amazon Account needs a{' '}
                  <strong>
                    default shipping address and payment method set
                  </strong>
                </>,
                <>
                  Account is <strong>NOT</strong> a{' '}
                  <strong>business account</strong>
                </>,
                <>
                  Does <strong>NOT</strong> have a{' '}
                  <strong>Gift Card/Credit</strong> balance
                </>,
                <>
                  Account language must be set to <strong>English</strong>
                </>
              ],
              extra: (
                <img
                  src="/onboarding-popup.svg"
                  className="hidden w-full rounded-lg lg:block lg:h-64"
                  alt="Ecommerce store"
                />
              )
            }
          ]),
      {
        title: "Let's get started",
        description:
          'This process takes about 10 minutes, but if you follow the instructions correctly you’ll be receiving boxes very soon!',
        extra: <SecretKeyExampleContainer skipToSignIn={skipToSignIn} />,
        desktopVideo: '',
        mobileVideo: '',
        first: true
      },
      {
        title: 'Ensure 2-factor authentication is disabled',
        list: [
          <p className="my-0" key="Go to this link">
            Go to{' '}
            <a
              href="https://www.amazon.com/a/settings/approval"
              target="_blank"
              rel="noreferrer"
            >
              this link
            </a>
          </p>,
          'Click the disable button',
          'Check the checkbox',
          'Click disable'
        ],
        desktopVideo: '/videos/desktop/ensure.mp4',
        mobileVideo: '/videos/mobile/ensure.mp4'
      },
      {
        title: 'Set up 2FA',
        list: [
          'Click the yellow "Get Started" button',
          'Select the Authenticator app option',
          "Click 'Can't Scan The Barcode?'",
          'Copy your 2FA Secret Key',
          'Paste your 2FA Secret Key in a safe place!'
        ],
        extra: (
          // <input
          //   className="w-full px-4 py-2 border rounded shadow-sm"
          //   placeholder="Paste your 2FA code here..."
          //   value={code}
          //   onChange={(e) => {
          //     if (e.target.value.match(/^[A-Z2-7]{32,64}$/)) {
          //       setCodeValid(true);
          //       setCode(e.target.value);
          //     } else {
          //       setCode("");
          //       setCodeValid(false);
          //       toast.error("You must paste a valid 2FA code");
          //     }
          //   }}
          // />
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center gap-2.5 rounded-full border border-blue-200 bg-blue-50 px-4 py-2.5 text-sm font-medium text-blue-500">
              <FaInfoCircle /> Your 2FA Secret Key is important! Save it for
              later.
            </div>
            <SecretKeyExample />
          </div>
        ),
        codeValid,
        desktopVideo: '/videos/desktop/setup.mp4',
        mobileVideo: '/videos/mobile/setup.mp4'
      },
      {
        title: 'Download Google Authenticator',
        description: (
          <div>
            <ol>
              <li>
                Get the <strong>FREE “Google Authenticator” app</strong> on your
                phone
              </li>
              <li>Click the “+” icon to add a code</li>
              <li className="hidden lg:list-item">
                Select “enter a setup key”
              </li>
              <li className="hidden lg:list-item">
                {' '}
                In the account box type “Amazon”
              </li>
              <li className="hidden lg:list-item">
                In the key box paste 2FA Secret Key
              </li>
              <li className="hidden lg:list-item">Press add code</li>
              <li className="list-item lg:hidden">Select “Scan a QR code”</li>
              <li className="list-item lg:hidden">
                Scan the QR on your Amazon page
              </li>
            </ol>
            <p className="mt-3 font-medium text-gray-500">
              Note: you do not have to pay for Google Authenticator–it&apos;s a
              free app.
            </p>
          </div>
        ),
        desktopVideo: '/videos/desktop/gauth.mp4',
        mobileVideo: '/videos/mobile/gauth.mp4'
      },
      {
        title: 'Complete 2-step setup',
        list: [
          'You will now see a 6 digit code generated in your authenticator app that changes every 30 seconds',
          'Enter 6 digit code into the box on Amazon',
          'Select “Verify OTP and continue”',
          'Click checkbox “don’t require OTP”',
          'Select “Got it. Turn on Two-Step Verification”'
        ],
        desktopVideo: '/videos/desktop/2step.mp4',
        mobileVideo: '/videos/mobile/2step.mp4',
        extra: (
          <div className="flex flex-row items-center gap-2.5 rounded-lg border border-blue-200 bg-blue-50 px-4 py-2.5 text-sm font-medium text-blue-500">
            <FaInfoCircle className="shrink-0" /> Your authenticator app will
            refresh 6 digit codes every 30 seconds.
          </div>
        )
      },
      {
        title: 'Sign in to Amazon',
        description: (
          <SignIn
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            setCurrentStep={setCurrentStep}
            goBackward={goBackward}
            code={code}
            setCode={setCode}
            codeValid={codeValid}
            setCodeValid={setCodeValid}
            currentStep={currentStep}
            setManualSetup={setManualSetup}
          />
        ),
        last: 'true',
        desktopVideo: '',
        mobileVideo: ''
      },
      {
        extra: (
          <div>
            <div className="mx-auto -mt-9 w-60">
              <Lottie animationData={robot} loop />
            </div>

            <h2 className="my-2.5 font-semibold">Attempting log in...</h2>
            <p className="">
              Please allow 1-3 minutes for full connection. You can click
              continue at any time.
            </p>
            <div className="p-5 mt-5 text-sm text-left text-gray-700 border rounded-lg shadow">
              <h3 className="mb-2.5 mt-0">Tips for success</h3>
              <p>
                • Expect at least 10 freebies a month, with many users getting
                their first within a week.
              </p>
              <p className="mt-2">
                • If you want more than just freebies, you can edit your account
                settings to set custom discount percentages and dollar amounts.
              </p>
              <p className="mt-2">
                • Contact customer support by pressing the widget in the bottom
                right anytime you need help.
              </p>
            </div>
            <button
              type="button"
              className="mt-5 flex w-full justify-center rounded-lg bg-gray-100 py-3.5 text-sm font-medium !text-black transition hover:bg-gray-200 active:bg-gray-300"
              onClick={() => {
                window.history.pushState({}, '', '/dashboard');
                window.location.reload();
              }}
            >
              Continue
            </button>
          </div>
        )
      }
    ],
    [
      code,
      codeValid,
      currentStep,
      email,
      goBackward,
      password,
      hasSeenOnboarding
    ]
  );

  const goForward = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prevStep => prevStep + 1);
    }
  }, [currentStep, steps]);

  const getTOTP = useCallback(() => {
    const newTOTP = new OTPAuth.TOTP({
      issuer: 'Amazon',
      label: 'FreebieFlow',
      algorithm: 'SHA1',
      digits: 6,
      period: 30,
      secret: code.replaceAll(' ', '')
    });
    setTOTP(newTOTP.generate());
  }, [code]);

  useEffect(() => {
    if (codeValid) {
      getTOTP();
    }
  }, [codeValid, getTOTP]);

  useEffect(() => {
    if (manualSetup === true) {
      setCurrentStep(5);
    }
  }, [manualSetup]);

  const onTimelineStepClick = useCallback(
    index => () => {
      if (index !== steps.length - 1) {
        setCurrentStep(index);
      }
    },
    [steps]
  );

  return (
    <main className="relative flex h-[calc(100vh-88px)] flex-col items-center gap-2.5  overflow-hidden overflow-y-scroll p-5 pb-32 bg-gray-50">
      <div className="z-0 blur-2xl">
        <div className="absolute top-40 w-[400px] -rotate-90 opacity-50 lg:w-[500px]">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fce7f3"
              d="M29.1,-52C35.4,-41.1,36.7,-29.3,41.9,-18.9C47.1,-8.5,56.1,0.4,59.1,11.4C62,22.4,59,35.4,51,43.8C43.1,52.2,30.3,56,17.9,58.7C5.4,61.4,-6.8,62.9,-21.3,63C-35.8,63.1,-52.7,61.7,-61.8,52.7C-70.9,43.7,-72.3,27.2,-72.4,11.7C-72.6,-3.7,-71.6,-18,-63.9,-26.9C-56.2,-35.7,-41.8,-39.2,-30.1,-47.6C-18.3,-56,-9.1,-69.2,1.1,-71C11.4,-72.7,22.7,-62.9,29.1,-52Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
        <div className="absolute -right-60 -top-52 w-[400px] opacity-50 lg:w-[1200px]">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fce7f3"
              d="M45.7,-75.4C55.9,-64.6,58.5,-46.2,64.8,-30.2C71,-14.2,81.1,-0.6,79.3,11.3C77.5,23.3,63.9,33.4,51.1,39.3C38.3,45.3,26.3,47,15.1,49.1C4,51.3,-6.3,53.8,-20.3,55.8C-34.2,57.7,-51.9,59,-64.7,51.8C-77.4,44.7,-85.3,29,-79.5,16.3C-73.7,3.6,-54.3,-6.2,-42.7,-14.5C-31.1,-22.9,-27.4,-29.9,-21.6,-42.7C-15.8,-55.5,-7.9,-74.2,4.9,-81.9C17.8,-89.5,35.5,-86.3,45.7,-75.4Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </div>
      <div className="z-10 flex my-5 gap-x-5">
        {steps.map((step, index) => (
          <button
            className={`w-2 h-2 lg:w-2.5 lg:h-2.5 rounded-full disabled:cursor-not-allowed ${
              currentStep === index ? 'bg-gray-500' : 'bg-gray-300'
            }`}
            key={step.title}
            onClick={onTimelineStepClick(index)}
            disabled={index === steps.length - 1}
            type="button"
            aria-label={`Go to step ${index + 1}`}
          />
        ))}
      </div>
      <Card
        key={currentStep}
        index={currentStep}
        title={steps[currentStep].title}
        video={steps[currentStep].video}
        description={steps[currentStep].description}
        list={steps[currentStep].list}
        manual={steps[currentStep].manual}
        goBackward={goBackward}
        goForward={goForward}
        currentStep={currentStep}
        first={steps[currentStep].first}
        last={steps[currentStep].last}
        setCurrentStep={setCurrentStep}
        manualSetup={manualSetup}
        mobileVideo={steps[currentStep].mobileVideo}
        desktopVideo={steps[currentStep].desktopVideo}
        extra={steps[currentStep].extra}
        code={code}
        setCode={setCode}
        codeValid={codeValid}
        buttonLabel={steps[currentStep].buttonLabel}
        className="z-10"
      />
    </main>
  );
}
