import React from 'react';

import axios from 'axios';
import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { toast } from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';

import { useDataContext } from 'context/UserContext';
import { ModalState } from 'dashboard/components/ConfirmCancelModal/constants';

function DialogButton({ children, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="mt-.5 flex w-full items-center justify-center gap-1.5 rounded-md bg-gray-100 px-5 py-3.5 text-xs font-bold tracking-wide text-gray-600 transition hover:bg-gray-200 hover:text-gray-800 active:bg-gray-300 active:text-black"
    >
      {children}
    </button>
  );
}

DialogButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export function Default({ setModalState }) {
  const [{ data: checkoutList = [] }] = useAxios('/analytics/checkouts');

  const checkoutTotal = checkoutList?.reduce((acc, cur) => {
    return acc + cur.price;
  }, 0);

  return (
    <>
      <div className="mt-5 mb-2.5 flex w-full gap-2.5">
        {checkoutTotal > 0 ? (
          <div className="flex w-full flex-col items-center justify-center rounded-md border p-2.5 shadow">
            <CountUp
              useEasing
              end={checkoutTotal}
              redraw={false}
              separator=","
              prefix="$"
              duration="4"
              enableScrollSpy
              className="text-gradient inline-block text-4xl font-extrabold"
            />
            <span className="text-center font-light tracking-tight">
              total saved
            </span>
          </div>
        ) : null}
        {checkoutList.length > 0 ? (
          <div className="flex w-full flex-col items-center justify-center rounded-md border p-2.5 shadow">
            <CountUp
              useEasing
              end={checkoutList?.length}
              redraw={false}
              separator=","
              duration="2"
              enableScrollSpy
              className="text-gradient inline-block text-4xl font-extrabold"
            />
            <span className="text-center font-light tracking-tight">
              packages
            </span>
          </div>
        ) : null}
      </div>

      <div className="mt-4 text-center text-sm font-medium leading-5 tracking-tight text-gray-800">
        Hey! We&apos;re sorry to see you go! To help us improve, why do you want
        to leave Freebie Flow?
      </div>
      <div className="mt-5 flex w-full flex-col gap-4">
        <DialogButton onClick={() => setModalState(ModalState.BOT)}>
          <div className="">My bot doesn&apos;t work</div>
        </DialogButton>
        <DialogButton onClick={() => setModalState(ModalState.AFFORD)}>
          <div className="">I can&apos;t afford it</div>
        </DialogButton>
        <DialogButton onClick={() => setModalState(ModalState.ITEMS)}>
          <div className="">I don&apos;t like the items I&apos;m getting</div>
        </DialogButton>
      </div>
    </>
  );
}

Default.propTypes = {
  setModalState: PropTypes.func.isRequired
};

export function Bot({ setGonnaCancel }) {
  const { user } = useDataContext();
  const { updateUser } = useDataContext();
  const { showNewMessage } = useIntercom();

  const handleCancel = async () => {
    axios
      .post('/cancel_subscription?cancelreason=botissues')
      .then(data => {
        updateUser();
        toast.success(data.data.status);
        setGonnaCancel(false);
        setTimeout(() => {
          window.location.reload(true);
        }, 2500);
      })
      .catch(e => {
        const error = e?.response?.data?.error;
        toast.error(error ?? 'Something went wrong!');
      });
    setGonnaCancel(false);
  };

  const connectBot = () => {
    showNewMessage(
      'Hello! I am currently facing issues with my bot and would like some assistance :) My issue is: '
    );
    setGonnaCancel(false);
  };

  return (
    <>
      <div className="mt-4 text-center text-sm font-medium leading-5 tracking-tight text-gray-800">
        We&apos;re sorry to hear that, {user.firstName}! Erik from our support
        team would be glad to help you out with your issues. Would you like to
        chat with him?
      </div>
      <div className="mt-5 flex flex-col gap-2">
        <DialogButton onClick={connectBot}>
          <div className="">Yes! Please put me through!</div>
        </DialogButton>
        <DialogButton onClick={handleCancel}>
          <div className="">Cancel Subscription</div>
        </DialogButton>
      </div>
    </>
  );
}

Bot.propTypes = {
  setGonnaCancel: PropTypes.func.isRequired
};

export function Pause({ setGonnaCancel }) {
  const { updateUser } = useDataContext();
  const { showNewMessage } = useIntercom();

  const handleCancel = async () => {
    axios
      .post('/cancel_subscription?cancelreason=cannotafford')
      .then(data => {
        updateUser();
        toast.success(data.data.status);
        setGonnaCancel(false);
        setTimeout(() => {
          window.location.reload(true);
        }, 2500);
      })
      .catch(e => {
        const error = e?.response?.data?.error;
        toast.error(error ?? 'Something went wrong!');
      });
    setGonnaCancel(false);
  };

  const handlePauseSubscription = () => {
    showNewMessage(
      "Hello! I can't afford next month's subscription and was wondering if I could pause my subscription for a few weeks or work something out :)"
    );
    setGonnaCancel(false);
  };

  return (
    <>
      <div className="mt-4 text-center text-sm font-medium leading-5 tracking-tight text-gray-800">
        We&apos;ve saved our users over $84,000,000 in free items and would hate
        to see you leave and miss out! Would you like to chat with our support
        team and see if we can work something out?
      </div>
      <div className="mt-5 flex flex-col gap-2">
        <DialogButton onClick={handlePauseSubscription}>
          <div className="">Yes! Chat with support team</div>
        </DialogButton>
        <DialogButton onClick={handleCancel}>
          <div className="">Cancel Subscription 😔</div>
        </DialogButton>
      </div>
    </>
  );
}

Pause.propTypes = {
  setGonnaCancel: PropTypes.func.isRequired
};

export function Cancel({ setGonnaCancel }) {
  const { updateUser } = useDataContext();

  const handleCancel = async () => {
    axios
      .post('/cancel_subscription?cancelreason=dislikesitems')
      .then(data => {
        updateUser();
        toast.success(data.data.status);
        setGonnaCancel(false);
        setTimeout(() => {
          window.location.reload(true);
        }, 2500);
      })
      .catch(e => {
        const error = e?.response?.data?.error;
        toast.error(error ?? 'Something went wrong!');
      });
    setGonnaCancel(false);
  };

  return (
    <>
      <div className="mt-4 text-center text-sm font-medium leading-5 tracking-tight text-gray-800">
        Mystery boxes can be random, and we&apos;ve checked out amazing items
        like drones and speakers in the past. By being a member for longer,
        you&apos;re more likely to get items like these! We&apos;ve saved our
        users over $84,000,000 in free items and would hate to see you leave and
        miss out!
      </div>
      <div className="mt-4 flex flex-col gap-2">
        <DialogButton onClick={handleCancel}>
          <div className="">Cancel Subscription 😔</div>
        </DialogButton>
      </div>
    </>
  );
}

Cancel.propTypes = {
  setGonnaCancel: PropTypes.func.isRequired
};
