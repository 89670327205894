export const formatPrice = price => {
  if (price === 0) return '0';
  if (price >= 1000000) {
    return `${(price / 1000000).toFixed(1)}m`;
  }
  if (price >= 1000) {
    return `${(price / 1000).toFixed(1)}k`;
  }
  return price.toFixed(2);
};
