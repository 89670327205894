import successImages from 'assets/success-images';

const reviewData = [
  {
    body: 'I have been using freebie flow for less than a month now and I love it. I didn’t expect to get as many packages as I did. The amount of money that I saved is amazing and will continue to using freebie flow.',
    name: 'Liz M.',
    details: 'FreebieFlow Customer',
    image: successImages[0]
  },
  {
    body: "I love Freebie Flow! Been here since April and no regrets. I'm able to get some sleep while FF works for me. Is like my personal assistant. I've saved so much money. My family loves FF too, they're getting freebies too! Last but not least, customer service is AMAZING!",
    name: 'Yaishirette P.',
    details: 'FreebieFlow Customer',
    image: successImages[1]
  },
  {
    body: "I approached Freebie Flow with skepticism, but after just a month, I found myself reveling in over 70 fantastic free and discounted packages!!!!! It's been an absolute thrill to receive these surprise packages, each one adding a spark of excitement to my day.",
    name: 'Brittany Kalinowski',
    details: 'FreebieFlow Customer',
    image: successImages[2]
  },
  {
    body: 'I’m a new subscriber and am already happy with my experience so far. 3 products have been ordered for me already having had the account a day or 2. And customer service has been really helpful and responsive as well',
    name: 'Randi T.',
    details: 'FreebieFlow Customer',
    image: successImages[3]
  },

  {
    body: 'I must say I was a bit hesitant at first. I thought this is just too good to be true. But once I gave it a try with the help of the amazing customer service team this past September. I fell in love with all the surprises I get weekly. I am hooked!',
    name: 'Deb B.',
    details: 'FreebieFlow Customer',
    image: successImages[4]
  },

  {
    body: "I have been doing FreebieFlow for about a month but already received way more than what covers the cost of the subscription. Their customer service is also really responsive. It's really fun to get surprises coming my way every week!",
    name: 'Melinda K.',
    details: 'FreebieFlow Customer',
    image: successImages[5]
  },

  {
    body: "I was extremely hesitant but had a moment where I just decided to try it out and ended up scoring my first freebie the next day! I'm now 4 days in and have 4 freebies scored valued at over $120! If you're hesitant, just try it! I'm loving this and so glad I jumped in!",
    name: 'Mermie E.',
    details: 'FreebieFlow Customer',
    image: successImages[6]
  },

  {
    body: 'I have only been trying freebie flow for a week and so far so good! I’ve been getting a decent amount of items! Wish I would have found out about it sooner. It’s fun and exciting to see what new packages you get to open everyday! 100% worth it! They have excellent service and a great website and telegram set up! Highly recommend!',
    name: 'Rachel B.',
    details: 'FreebieFlow User',
    image: successImages[7]
  },

  {
    body: "This is a pretty cool app. You can select how much you would like to spend or save. It's full of surprises and I like that. Thinking about upgrading just for fun! Try it for the first month.",
    name: 'Kitty L.',
    details: 'FreebieFlow User',
    image: successImages[8]
  },

  {
    body: "This website is so easy and Exciting. All you do is pay for the month at a reasonable price, then check everyday to see what hit. This is something everybody needs to try, it's Great!",
    name: 'Megan S.',
    details: 'FreebieFlow Fan',
    image: successImages[9]
  },

  {
    body: 'No need to wait around for amazing Deals with Freebies Flow; they’re easy to access and make incredible savings possible!',
    name: 'Shannon W.',
    details: 'Frequent Freebie User',
    image: successImages[10]
  }
];

export default reviewData;
