import React, { useEffect } from 'react';

import useAxios from 'axios-hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { useDataContext } from 'context/UserContext';
import AccountManager from 'dashboard/sections/AccountManager';
import CheckoutHistory from 'dashboard/sections/CheckoutHistory';
import Graph from 'dashboard/sections/Graph';
import TopCards from 'dashboard/sections/TopCards';
import { plans } from 'data/config';
import {
  checkForFailedPayment,
  checkForPausedSubscription
} from 'utils/dashboard';
import DynamicAnnouncement from 'dashboard/components/DynamicAnnouncement';

export default function Dashboard() {
  const { update } = useIntercom();
  const { user } = useDataContext();
  const navigate = useNavigate();

  const showOnboarding = !user?.hasSeenOnboarding;

  useEffect(() => {
    checkForFailedPayment(user);
    checkForPausedSubscription(user);
  }, [user]);

  const [{ data: checkoutList = [] }] = useAxios('/analytics/checkouts');

  let subscriptionName;
  try {
    subscriptionName = plans.find(
      plan => plan.priceId === user.subscription.price_id
    ).name;
  } catch (error) {
    subscriptionName = 'None';
  }

  update({
    email: user.username,
    userId: user._id.toString(),
    customAttributes: {
      'First Name': user.firstName,
      'Last Name': user.lastName,
      'Created At': user.createdAt.toString(),
      Subscription: subscriptionName,
      'Is Cancelling': user.subscription.deleted,
      'Last Checkout': checkoutList[0]?.time.toString() || 'None',
      Deals: checkoutList.filter(checkout => checkout.newPrice !== 0).length,
      Freebies: checkoutList.filter(checkout => checkout.newPrice === 0).length,
      'Total Checkouts': checkoutList.length,
      NOTE: 'This data is only updated when the user visits the settings or dashboard page. It may be out of date. Please refer to retool for the latest info.'
    }
  });

  try {
    window.posthog.identify(user._id.toString(), {
      email: user.username,
      'First Name': user.firstName,
      'Last Name': user.lastName,
      'Created At': user.createdAt.toString(),
      Subscription: subscriptionName,
      'Is Cancelling': user.subscription.deleted,
      'Last Checkout': checkoutList[0]?.time.toString() || 'None',
      Deals: checkoutList.filter(checkout => checkout.newPrice !== 0).length,
      Freebies: checkoutList.filter(checkout => checkout.newPrice === 0).length,
      'Total Checkouts': checkoutList.length,
      NOTE: 'This data is only updated when the user visits the settings or dashboard page. It may be out of date. Please refer to retool for the latest info.'
    });
  } catch (e) {
    console.log(e);
  }

  try {
    window.clearbit.identify(user._id.toString(), {
      email: user.username,
      'First Name': user.firstName,
      'Last Name': user.lastName,
      'Created At': user.createdAt.toString(),
      Subscription: subscriptionName,
      'Is Cancelling': user.subscription.deleted,
      'Last Checkout': checkoutList[0]?.time.toString() || 'None',
      Deals: checkoutList.filter(checkout => checkout.newPrice !== 0).length,
      Freebies: checkoutList.filter(checkout => checkout.newPrice === 0).length,
      'Total Checkouts': checkoutList.length,
      NOTE: 'This data is only updated when the user visits the settings or dashboard page. It may be out of date. Please refer to retool for the latest info.'
    });
  } catch (e) {
    console.log(e);
  }

  useEffect(() => {
    if (showOnboarding) {
      navigate('/dashboard/sync');
    }
  }, [showOnboarding, navigate]);

  return (
    <div className="flex flex-col gap-5 bg-zinc-50 p-5 xl:h-[calc(100vh-88px)] xl:flex-row xl:overflow-hidden">
      <DynamicAnnouncement audience={'Subscribed'} />
      <div className="order-2 w-full xl:order-1 xl:w-1/4">
        <PerfectScrollbar>
          <AccountManager />
        </PerfectScrollbar>
      </div>

      <div className="order-1 w-full xl:order-2 xl:w-2/4">
        <div className="mb-2.5 font-semibold">Dashboard</div>
        <TopCards />
        <Graph />
      </div>

      <div className="order-3 w-full xl:order-3 xl:w-1/4">
        <PerfectScrollbar>
          <CheckoutHistory />
        </PerfectScrollbar>
      </div>
    </div>
  );
}
