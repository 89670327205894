import React from 'react';

import { FaCheck } from 'react-icons/fa';

const features = [
  {
    name: 'Expert support',
    description:
      'Benefit from our knowledgeable support team who are always available to answer your questions and assist you in setting up quickly.'
  },
  {
    name: 'Money back guarantee',
    description:
      "With our money back guarantee, we ensure a refund if you don't successfully obtain a freebie in your first month."
  },
  {
    name: 'Membership panel',
    description:
      'Effortlessly manage your subscription with our membership panel, which provides access to account management services, billing history, and a one-click cancellation button.'
  },
  {
    name: '24/7 product discovery',
    description:
      'Our powerful bot scans 1.2 million products every day, working around the clock to find the best deals and freebies available.'
  }
  // {
  //   name: "Flexible plans",
  //   description:
  //     "Select the most suitable account plan for your needs at a budget-friendly price.",
  // },
  // {
  //   name: "Easy-to-use interface",
  //   description:
  //     "Navigate our user interface with ease, whether you're using a computer or a mobile device.",
  // },
];

export default function Features() {
  return (
    <div id="features" className="bd-gradient relative z-30 px-5 lg:px-10">
      <div className="z-11 relative mx-auto max-w-screen-xl py-20 lg:grid lg:grid-cols-3 lg:gap-x-5">
        <div>
          <h2
            data-aos="fade-up"
            className="text-gradient inline-block text-base font-medium"
          >
            Features
          </h2>
          <p
            data-aos="fade-up"
            className="mt-2 text-3xl font-semibold tracking-tight text-gray-900"
          >
            Everything you need
          </p>
          <p data-aos="fade-up" className="mt-4 text-base text-gray-700">
            With our advanced technology, securing incredible discounts online
            has never been easier. Simply set your discount parameters and let
            our software handle the rest, even purchasing the discounted items
            for you.
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="grid gap-10 sm:grid-cols-2 sm:gap-x-6">
            {features.map((feature, i) => (
              <div
                data-aos="fade-up"
                data-aos-delay={i * 100}
                key={feature.name}
                className="relative"
              >
                <dt className="flex items-center">
                  <FaCheck
                    className="bg-gradient absolute left-0 rounded-full p-1.5 text-2xl text-white"
                    aria-hidden="true"
                  />
                  <p className="ml-8 text-lg font-medium leading-6 text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-8 text-base text-gray-700">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
