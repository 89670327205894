import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';

import axios from 'axios';

export default function DynamicAnnouncement({ audience }) {
  const [isOpen, setIsOpen] = useState(false);
  const [announcementData, setAnnouncementData] = useState({});

  const { _id, title, description } = announcementData.data || {};

  useEffect(() => {
    axios.get(`/announcements/last?audience=${audience}`).then(response => {
      if (response.data.success) {
        setAnnouncementData(response.data);
        if (response.data) {
          setIsOpen(true);
        }
      }
    });
  }, []);

  const closeModal = optOut => {
    if (audience === 'Subscribed' && optOut) {
      axios.put(`/announcements/optOutUser?id=${_id}`);
    }
    setIsOpen(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => closeModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full text-center max-w-md transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6"
                  >
                    {title}
                  </Dialog.Title>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>

                  {announcementData.data?.gif ||
                    (announcementData.data?.image && (
                      <div className="mt-5 flex justify-center">
                        <img
                          className="rounded-md"
                          src={
                            announcementData.data?.gif
                              ? announcementData.data?.gif
                              : announcementData.data?.image
                          }
                          alt="Announcement Image File"
                        />
                      </div>
                    ))}

                  <div className="mt-5">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-black-900 hover:opacity-75"
                      onClick={() => closeModal(true)}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                  <div className="absolute top-0 right-0 mt-4 mr-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-black-900 hover:opacity-75"
                      onClick={() => closeModal(false)}
                    >
                      <FaTimes />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

DynamicAnnouncement.propTypes = {
  audience: PropTypes.string.isRequired
};
