import React from 'react';

import axios from 'axios';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { DataProvider } from './context/UserContext';

axios.defaults.baseURL =
  process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </CookiesProvider>
  </BrowserRouter>
);
