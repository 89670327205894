import React, { useEffect, useState } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import { FaPlus, FaLock } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { useDataContext } from 'context/UserContext';
import AccountCard from 'dashboard/components/AccountCard';
import AddAccount from 'dashboard/components/AddAccount';
import DeleteAccountModal from 'dashboard/components/DeleteAccountModal';
import EditModal from 'dashboard/components/EditModal';
import { displayDiscount } from 'utils/discount';
import AuthSupportModal from 'dashboard/components/AuthSupportModal';

const socketUrl = `${process.env.REACT_APP_SOCKET_URL}accounts/stream`;
// `wss://api.freebieflow.com/accounts/stream`; // `${process.env.REACT_APP_SOCKET_URL}/accounts/stream`;

function AccountManager() {
  const navigate = useNavigate();

  const [accountList, setAccountList] = useState([]);
  const [supportOpen, setSupportOpen] = useState(false);
  const [wsHasUpdated, setwsHasUpdated] = useState(false);
  const [mainErrorMessage, setMainErrorMessage] = useState("");
  const [mainErrorEmail, setMainErrorEmail] = useState("");
  const [hasSupportOpened, setHasSupportOpened] = useState(false);

  const { lastJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    reconnectAttempts: 25,
    reconnectInterval: 5000
  });

  useEffect(() => {
    if (!wsHasUpdated) {
      axios('/accounts').then(res => {
        setAccountList(res.data);
      });
    }
  }, []);

  useEffect(() => {
    if (!lastJsonMessage) return;
    const { data, type } = lastJsonMessage;
    const accountId = lastJsonMessage.account;
    switch (type) {
      case 'update':
        setwsHasUpdated(true);
        const newAccounts = [];
        for (const account of accountList) {
          const newAccount = { ...account };
          if (accountId === account._id) {
            for (const removedField of data.removedFields) {
              delete newAccount[removedField];
            }
            for (const [key, value] of Object.entries(data.updatedFields)) {
              // Dot notation handling, not sure why it sends metadata in this format.
              if (key.includes('.')) {
                const [first, second] = key.split('.');
                if (!newAccount[first]) newAccount[first] = {};
                newAccount[first][second] = value;
                continue;
              }

              newAccount[key] = value;
            }
          }
          newAccounts.push(newAccount);
        }
        setAccountList(newAccounts);
        break;
      case 'insert':
        setAccountList([...accountList, data]);
        break;
      case 'delete':
        setAccountList(accountList.filter(a => a._id !== accountId));
        break;
      default:
        break;
    }
  }, [lastJsonMessage]);

  const { user } = useDataContext();

  const [activeAccount, setActiveAccount] = useState({});
  const [dealAccount, setDealAccount] = useState(false);
  const [requestedAPI, setRequestedAPI] = useState(false);
  const [addAccountModal, setAddAccountModalOld] = useState(false);
  const [editAccountModal, setEditAccountModalOld] = useState(false);
  const [deleteAccountModal, setDeleteAccountModalOld] = useState(false);

  const [remainingSlots, setRemainingSlots] = useState(null);
  const [remainingDealSlots, setRemainingDealSlots] = useState(null);

  const resetHistory = () => {
    window.history.pushState({}, '', '/dashboard');
  };

  const setAddAccountModal = (value, isDeal) => {
    resetHistory();
    setAddAccountModalOld(value);
    setDealAccount(isDeal);
  };

  const setEditAccountModal = value => {
    resetHistory();
    setEditAccountModalOld(value);
  };

  const setDeleteAccountModal = value => {
    resetHistory();
    setDeleteAccountModalOld(value);
  };

  useEffect(() => {
    setRemainingSlots(user.maxAccounts - accountList.length);
    setRemainingDealSlots(
      user.maxDealAccounts - accountList.filter(a => a.isDealAccount).length
    );
  }, [user.maxAccounts, user.maxDealAccounts, accountList]);

  const togBot = account => {
    setRequestedAPI(true);
    axios('/accounts', {
      method: 'PATCH',
      data: {
        _id: account._id,
        active: !account.active
      }
    }).then(() => {
      setRequestedAPI(false);
      if (account.active) {
        toast.error(`Your account is now inactive.`);
      } else {
        toast.success(`Your account is now active.`);
      }
    });
  };

  function AccountStatus({ account }) {
    const [status, setStatus] = useState(
      !account.metadata?.error && account.metadata?.cookie ? (
        account.active ? (
          Number(account.controls.discount) === 100 ? (
            <div>
              FreebieFlow is active. Your settings allow only{' '}
              <strong className="whitespace-nowrap">freebies</strong>.
            </div>
          ) : (
            <div>
              FreebieFlow is active. Your settings allow all freebies and deals
              with at least a{' '}
              <strong className="whitespace-nowrap">
                {displayDiscount(account.controls.discount)}% discount
              </strong>{' '}
              and{' '}
              <strong className="whitespace-nowrap">
                max price of ${account.controls.maxPrice}
              </strong>
              .
            </div>
          )
        ) : (
          'Press start to secure your freebies!'
        )
      ) : account.metadata?.error ? (
        `Error logging in: ${account.metadata.errorMessage}`
      ) : (
        'Logging in'
      )
    );

    useEffect(() => {
      if (
        !account.metadata?.error &&
        account.metadata?.cookie &&
        !account.active
      ) {
        if (new Date(account.createdAt) > Date.now() - 1000 * 60 * 1) {
          setActiveAccount(account);
          togBot(account);
        } else {
          setStatus(
            account.active
              ? `Searching for deals...`
              : 'Press start to secure your freebies!'
          );
        }
      } else if (!account.active) {
        setStatus(
          account.metadata?.error
            ? `Error logging in: ${account.metadata.errorMessage}`
            : 'Logging in'
        );
        if (account.metadata?.error && !hasSupportOpened) {
          console.log("DISPLAYING ERROR!");
          setMainErrorMessage(account?.metadata?.errorMessage);
          setMainErrorEmail(account?.email);
          setSupportOpen(true);
          setHasSupportOpened(true);

        }
      }
    }, [account]);
    return (
      <>
        {status}
        {account.metadata?.error && (
          <button
            onClick={() => setSupportOpen(true)}
            className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-1 py-1 font-small leading-none text-button-text transition-all lg:text-xs mt-2"
            >
            Contact Support For Help
          </button>
        )}
      </>
    );
  }

  const canCreateAccounts = accountList.length < user.maxAccounts;
  return (
    <>
      <div className="scrollbar-left mt-5 flex h-full w-full flex-col lg:mt-0">
        <div className="text-gray-800">
          <div className="mb-2.5 font-semibold">Your accounts</div>

          <div className="flex flex-col gap-2.5">
            {accountList.map((account, index) => {
              return (
                <AccountCard
                  key={account.email}
                  isLoading={requestedAPI}
                  account={account}
                  index={index}
                  setActiveAccount={setActiveAccount}
                  setEditAccountModal={setEditAccountModal}
                  setDeleteAccountModal={setDeleteAccountModal}
                  togBot={togBot}
                  AccountStatus={AccountStatus}
                />
              );
            })}
          </div>
        </div>
        {canCreateAccounts ? (
          <div className="mt-2.5 flex flex-col gap-2.5">
            {[...Array(remainingSlots)].map(slot => (
              <button
                onClick={() => {
                  navigate('/dashboard/sync');
                }}
                key={slot}
                type="button"
              >
                <div className="flex h-32 items-center justify-center rounded-md border bg-white px-5 text-gray-400 shadow-sm transition hover:text-black">
                  <div className="flex gap-1.5 text-sm leading-none">
                    <FaPlus /> Click to add a freebie account!
                  </div>
                </div>
              </button>
            ))}
            {[...Array(remainingDealSlots)].map(slot => (
              <button
                onClick={() => {
                  setAddAccountModal(true, true);
                  window.history.pushState({}, '', '/dashboard/add');
                }}
                key={slot}
                type="button"
              >
                <div className="flex h-32 items-center justify-center border-b px-5 text-gray-400 transition hover:bg-gray-100 hover:text-gray-700 active:bg-gray-200">
                  <div className="flex gap-1.5 text-sm leading-none">
                    <FaPlus /> Click to add a deal account!
                  </div>
                </div>
              </button>
            ))}
          </div>
        ) : null}
        <Link to="/dashboard/settings">
          <div className="mt-2.5 flex h-32 items-center justify-center rounded-md border bg-white px-5 text-gray-400 shadow-sm transition hover:text-black">
            <div className="flex gap-1.5 text-sm leading-none">
              <FaLock /> Buy more account slots.
            </div>
          </div>
        </Link>
      </div>

      <EditModal
        editAccountModal={editAccountModal}
        setEditAccountModal={setEditAccountModal}
        activeAccount={activeAccount}
        setActiveAccount={setActiveAccount}
        onUpdate={() => { }}
      />
      <AddAccount
        addAccountModal={addAccountModal}
        setAddAccountModal={setAddAccountModal}
        dealAccount={dealAccount}
        onAdd={() => { }}
      />
      <DeleteAccountModal
        deleteAccountModal={deleteAccountModal}
        setDeleteAccountModal={setDeleteAccountModal}
        activeAccount={activeAccount}
        onDelete={() => { }}
      />
      <AuthSupportModal supportOpen={supportOpen} setSupportOpen={setSupportOpen} error={mainErrorMessage} accountEmail={mainErrorEmail} />

    </>

  );

}


export default AccountManager;

