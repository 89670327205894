/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import logo from 'assets/logo.png';
import { useDataContext } from 'context/UserContext';
import ForgotPasswordModal from 'dashboard/components/ForgotPasswordModal';

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotOpen, setForgotOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { user } = useDataContext();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const postLogin = async e => {
    e.preventDefault();
    setErrorMessage('');

    axios('/login', {
      method: 'POST',
      data: { username: email, password }
    })
      .then(() => {
        window.location.reload(true);
        toast.success('Signed in successfully');
      })
      .catch(e2 => {
        const error = e2?.response?.data?.error;
        toast.error(error ?? 'Something went wrong!');
        setErrorMessage(error ?? 'Something went wrong!');

      });
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <section className="px-5">
      <div className="flex min-h-full flex-col justify-center bg-background py-12 sm:px-6 lg:px-8">
        <Blobs />
        <div className="relative z-20 sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img className="mx-auto h-12 w-auto" src={logo} alt="FreebieFlow" />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-semibold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-800">
            Or{' '}
            <Link
              to="/dashboard/register"
              className="text-gradient font-medium"
            >
              Register
            </Link>
          </p>
        </div>
        <div className="relative z-20 mt-8 overflow-hidden rounded-md sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-gradient h-1 w-full rounded-t-md" />
          <div className="rounded-md border-x bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={postLogin} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-800"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-800"
                >
                  Password
                </label>
                <div className="relative mt-1">
                  <input
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 py-1"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <FaEyeSlash className="text-gray-400" />
                    ) : (
                      <FaEye className="text-gray-400" />
                    )}
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="button-gradient flex w-full justify-center"
                >
                  Sign in
                </button>
                {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}

              </div>
            </form>
            <div className="mx-auto mt-5 flex justify-center text-xs">
              <button
                onClick={() => setForgotOpen(true)}
                className="text-gradient"
                type="button"
              >
                Forgot your password?
              </button>
            </div>
          </div>
        </div>
      </div>
      <ForgotPasswordModal
        forgotOpen={forgotOpen}
        setForgotOpen={setForgotOpen}
      />
    </section>
  );
}

function Blobs() {
  return (
    <div className="absolute top-0 right-60 h-screen w-[400px] overflow-hidden opacity-75 lg:w-[1200px]">
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#fce7f3"
          d="M45.7,-75.4C55.9,-64.6,58.5,-46.2,64.8,-30.2C71,-14.2,81.1,-0.6,79.3,11.3C77.5,23.3,63.9,33.4,51.1,39.3C38.3,45.3,26.3,47,15.1,49.1C4,51.3,-6.3,53.8,-20.3,55.8C-34.2,57.7,-51.9,59,-64.7,51.8C-77.4,44.7,-85.3,29,-79.5,16.3C-73.7,3.6,-54.3,-6.2,-42.7,-14.5C-31.1,-22.9,-27.4,-29.9,-21.6,-42.7C-15.8,-55.5,-7.9,-74.2,4.9,-81.9C17.8,-89.5,35.5,-86.3,45.7,-75.4Z"
          transform="translate(100 100)"
        />
      </svg>
    </div>
  );
}
