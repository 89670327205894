import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

function Skeleton({ classes }) {
  return (
    <div
      className={clsx(
        classes || 'h-4',
        `w-full animate-pulse rounded-lg bg-gray-200`
      )}
    />
  );
}

Skeleton.propTypes = {
  classes: PropTypes.string.isRequired
};

export default Skeleton;
