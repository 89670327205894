import React from 'react';

import PropTypes from 'prop-types';
import { FaInfoCircle, FaExclamationCircle } from 'react-icons/fa';

const SECRET_KEY_EXAMPLE =
  'HBRQJX7PTKYPZV3XN6UKNGFBNISWH5MMHFTMVWWONGWOSEJAKB3S';

const SPACED_SECRET_KEY_EXAMPLE = SECRET_KEY_EXAMPLE.match(/.{1,4}/g).join(' ');

function SecretKeyExample() {
  return (
    <div>
      <strong className="text-sm font-bold uppercase tracking-wide opacity-60">
        Example 2FA secret key
      </strong>
      <p className="my-0 text-sm font-medium select-none">
        {SPACED_SECRET_KEY_EXAMPLE}
      </p>
    </div>
  );
}

function SecretKeyExampleContainer({ skipToSignIn }) {
  return (
    <div className="w-full rounded-3xl border p-5 shadow">
      <div className="mb-5 items-center gap-1.5 font-bold">
        <FaInfoCircle className="relative mr-1 mb-0.5 inline-block" /> Amazon
        2FA Secret Keys look like this:
      </div>
      <SecretKeyExample />
      <button
        onClick={skipToSignIn}
        className="flex items-center gap-1.5 rounded-full px-5 py-2 text-sm font-medium bg-gray-100 !text-gray-500  transition hover:bg-gray-200 hover:!text-gray-800 active:bg-gray-200 flex-shrink-0 mt-5"
        type="button"
      >
        <FaExclamationCircle className="flex-shrink-0" />{' '}
        <span className="flex-shrink-0">I know my key</span>
      </button>
    </div>
  );
}

SecretKeyExampleContainer.propTypes = {
  skipToSignIn: PropTypes.func.isRequired
};

export { SecretKeyExample, SecretKeyExampleContainer, SECRET_KEY_EXAMPLE };
