import React from 'react';

import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function CTA() {
  return (
    <div id="cta" data-aos="fade-up" className="mt-12 px-5">
      <div className="bg-gradient mx-auto max-w-7xl rounded-xl from-highlight to-[#008fff] py-8 px-5 lg:px-0">
        <h2 className="text-center font-semibold tracking-tight text-white sm:text-4xl">
          <span className="mb-1 block text-lg font-light lg:text-2xl">
            Ready to dive in?
          </span>
          <span className="block text-xl text-white lg:text-2xl">
            Start receiving freebies today.
          </span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="flex justify-center gap-2.5 rounded-md bg-white shadow-md">
            <Link
              to="/dashboard/register"
              className="text-gradient inline-flex items-center gap-1.5 rounded-md bg-white px-4 py-3 font-medium leading-none tracking-wide transition-all hover:bg-gray-50 active:bg-gray-100 lg:text-sm"
            >
              <span className="text-gradient">Get Started</span>
              <FaChevronRight className="text-xs text-violet-500" />
            </Link>
          </div>
        </div>{' '}
        <p className="mt-2.5 text-white text-xs leading-5 w-full text-center">
          Easily cancel at any time, no questions asked.
        </p>
      </div>
    </div>
  );
}
