import React from 'react';

import PropTypes from 'prop-types';

import Steps from 'steps/Steps';

function AddAccount({ addAccountModal, setAddAccountModal, onAdd }) {
  if (addAccountModal) {
    return (
      <Steps
        onDone={onAdd}
        setAddAccountModal={setAddAccountModal}
        close={() => setAddAccountModal(false)}
        isOpen={addAccountModal}
      />
    );
  }
}

AddAccount.propTypes = {
  addAccountModal: PropTypes.bool.isRequired,
  setAddAccountModal: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};

export default AddAccount;
